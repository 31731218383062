/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const getAllBots = () => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/bot/get-all`);
}

export const botService = {
  getAllBots
}
