import moment from "moment";
import { TYPES } from "../actions";

const initialState = {
  antInfoCollections: [],
  favorite: null
};

const FAVORITE_NAME = '__FAVORITES__';

const filterAntInfoCollection = (data) => {
  return data.filter(antInfoCollection => antInfoCollection.name != FAVORITE_NAME);
}

const filterFavorite = (data) => {
  if (data.length == 0)
    return null;

  return data.filter(antInfoCollection => antInfoCollection.name == FAVORITE_NAME)[0];
}

export const antInfoCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_GET_ALL_ANTINFOCOLLECTIONS_SUCCESS:
      return { ...state,
        antInfoCollections: filterAntInfoCollection(action.payload.data),
        favorite: filterFavorite(action.payload.data)
      }

    case TYPES.ACTION_ADD_NEW_ANTINFOCOLLECTION_SUCCESS:
      return { ...state, 
        antInfoCollections: [...state.antInfoCollections, action.payload.data]
      }

    case TYPES.ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_SUCCESS:
      if (action.payload.data.name == FAVORITE_NAME)
        return { ...state,
          favorite: action.payload.data
        };

      return { ...state, 
        antInfoCollections: state.antInfoCollections.map(antInfoCollection => 
          antInfoCollection._id == action.payload.data._id? action.payload.data: antInfoCollection)
      }

    case TYPES.ACTION_UPDATE_ANTINFOCOLLECTION_SUCCESS:
      if (action.payload.data.status == -1) { //delete
        return { ...state, 
          antInfoCollections: state.antInfoCollections.filter(antInfoCollection => antInfoCollection._id != action.payload.data._id)
        }
      }

      return { ...state, 
        antInfoCollections: state.antInfoCollections.map(antInfoCollection => 
          antInfoCollection._id == action.payload.data._id? action.payload.data: antInfoCollection)
      }

    case TYPES.ACTION_ADD_ALL_LISTING_TO_NEW_ANTINFOCOLLECTION_SUCCESS:
      return { ...state, 
        antInfoCollections: [...state.antInfoCollections, action.payload.data.antInfoCollection]
      }
      
    default:
      return state;
  }
};
