/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import { formatTime, getTranslate, LANGUAGE_KEYS } from "../../utils";
import moment from "moment";


import {BsFillSuitHeartFill} from "react-icons/bs";
// https://react-icons.github.io/react-icons/icons?name=bs

class ShopItem extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }


  componentDidMount() {
  }

  componentWillReceiveProps(props) {
  }

  lastSoldDay = (sale_90_days_list) => {
    var i = 0;

    while (sale_90_days_list[i] == 0 && i < sale_90_days_list.length - 1)
      i++;

    return i;
  }

  formatLastUpdateDays = (last_updated_days) => {
    var d = Math.floor(last_updated_days);
    var h = Math.round((last_updated_days - d) * 24);

    var str = "";

    if (d > 0)
      str += `${d} ${getTranslate(LANGUAGE_KEYS.TEXT_DAY)} `;

    str += `${h} ${getTranslate(LANGUAGE_KEYS.TEXT_HOUR)}`;
    
    return str;
  }

  render() {
    var shop = this.props.shop;

    return (
      <Card id='shop-item'>
        <a href={`https://etsy.com/shop/${shop.shop_name}`} target="_blank" title='View this shop on Etsy'>
          <Card.Img variant="top" src={shop.img_shop_avatar_url || 'https://www.webike.vn/frontend/img/common/shop_default.jpg'} />
        </a>
        <Card.Title as="h5" className="pt-3 pl-3">
          <Badge pill bg={`${shop.status == 2? 'danger': 'success'}`} title={shop.status == 2? 'Suspended!': 'Active'}>
            {shop.shop_name}
            </Badge>
        </Card.Title>
        <Card.Body>
            <Table>
              <tbody>
                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_SALES)}</td>
                  <td className="value">
                    <Badge pill bg="danger">
                      {shop.total_sales_hey}
                    </Badge>
                  </td>
                </tr>
                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_SHOP_SOLD_90_DAYS)}</td>
                  <td className="value">
                    <Badge pill bg="danger">
                      {shop.sale_90_days}
                    </Badge>
                  </td>
                </tr>

                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_RATING)}</td>
                  <td className="value">
                    <Badge pill bg="danger">
                      {`${Math.round(shop.rating_value * 100) / 100} ★`}
                    </Badge>
                  </td>
                </tr>

                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_LAST_SOLD_DAYS)}</td>
                  <td className="value">
                    <Badge pill bg="primary">
                      {`${this.lastSoldDay(shop.sale_90_days_list)} ${getTranslate(LANGUAGE_KEYS.TEXT_DAY)}`}
                    </Badge>
                  </td>
                </tr>

                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_LAST_UPDATE)}</td>
                  <td className="value">
                    <Badge pill bg="primary">
                      {this.formatLastUpdateDays(shop.last_updated_days)}
                    </Badge>
                  </td>
                </tr>

                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_OPENING_DATE)}</td>
                  <td className="value">
                    <Badge pill bg="primary">
                      {/* {formatTime(shop.opening_date, "DD/MM/YYYY")} */}
                      {moment(shop.opening_date).fromNow()}
                    </Badge>
                  </td>
                </tr>

                <tr>
                  <td>{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_LISTINGS)}</td>
                  <td className="value">
                    <Badge pill bg="info">
                      {shop.total_listings}
                    </Badge>
                  </td>
                </tr>
              </tbody>
            </Table>
        </Card.Body>
        <Card.Footer>
        </Card.Footer>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { })(ShopItem);