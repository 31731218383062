/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const getAllAntInfoCollections = () => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/collection/get-all`);
}

export const addNewAntInfoCollection = (antInfoCollectionName) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/collection`, [
    {
      name: antInfoCollectionName,
      status: 1
    }
  ]);
}

export const updateAntInfoCollection = (antInfoCollection) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/collection`, [
    {
      _id: antInfoCollection._id,
      name: antInfoCollection.name,
      description: antInfoCollection.description,
      status: antInfoCollection.status
    }
  ]);
}

export const toggleListingInAntInfoCollection = (listing, antInfoCollectionId) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/collection/toggle-listing`, {
    listing_id: listing.listing_id.toString(),
    img_cover_url: listing.img_cover_url,
    collection_id: antInfoCollectionId
  });
}

export const antInfoCollectionService = {
  getAllAntInfoCollections,
  addNewAntInfoCollection,
  toggleListingInAntInfoCollection,
  updateAntInfoCollection
}
