import { TYPES } from "./types";
import { antInfoCollectionService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getAllAntInfoCollections = () => async (dispatch) => {
  try {
    const result = await antInfoCollectionService.getAllAntInfoCollections();
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_ANTINFOCOLLECTIONS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_ANTINFOCOLLECTIONS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const addNewAntInfoCollection = (antInfoCollectionName) => async (dispatch) => {
  try {
    const result = await antInfoCollectionService.addNewAntInfoCollection(antInfoCollectionName);
    const data = result.data.data;

    console.log('data', data);
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_ADD_NEW_ANTINFOCOLLECTION_SUCCESS,
        payload: { data: data[0] },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_ADD_NEW_ANTINFOCOLLECTION_FAIL,
        payload: {},
      });
    }
  } 
  
  catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const toggleListingInAntInfoCollection = (listing, antInfoCollectionId) => async (dispatch) => {
  try {
    const result = await antInfoCollectionService.toggleListingInAntInfoCollection(listing, antInfoCollectionId);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const updateAntInfoCollection = (antInfoCollection) => async (dispatch) => {
  try {
    const result = await antInfoCollectionService.updateAntInfoCollection(antInfoCollection);
    const data = result.data.data[0];
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_UPDATE_ANTINFOCOLLECTION_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_UPDATE_ANTINFOCOLLECTION_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}


export const antInfoCollectionAction = {
  getAllAntInfoCollections,
  addNewAntInfoCollection,
  toggleListingInAntInfoCollection
}
