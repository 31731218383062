export const TYPES = {
  // authen
  ACTION_LOGIN_SUCCESS: "ACTION_LOGIN_SUCCESS",
  ACTION_LOGIN_FAIL: "ACTION_LOGIN_FAIL",
  ACTION_CALL_API_FAIL: "ACTION_CALL_API_FAIL",

  ACTION_GET_USER_SUCCESS: "ACTION_GET_USER_SUCCESS",
  ACTION_GET_USER_FAIL: "ACTION_GET_USER_FAIL",

  ACTION_GET_EXERCISE_ACTION_CONFIG_SUCCESS: "ACTION_GET_EXERCISE_ACTION_CONFIG_SUCCESS",
  ACTION_GET_EXERCISE_ACTION_CONFIG_FAIL: "ACTION_GET_EXERCISE_ACTION_CONFIG_FAIL",

  ACTION_GET_MY_EXERCISES_SUCCESS: "ACTION_GET_MY_EXERCISES_SUCCESS",
  ACTION_GET_MY_EXERCISES_FAIL: "ACTION_GET_MY_EXERCISES_FAIL",

  ACTION_GET_MY_DIARIES_SUCCESS: "ACTION_GET_MY_DIARIES_SUCCESS",
  ACTION_GET_MY_DIARIES_FAIL: "ACTION_GET_MY_DIARIES_FAIL",

  ACTION_GET_BODY_RECORD_HOUR_SUCCESS: "ACTION_GET_BODY_RECORD_HOUR_SUCCESS",
  ACTION_GET_BODY_RECORD_HOUR_FAIL: "ACTION_GET_BODY_RECORD_HOUR_FAIL",

  ACTION_GET_BODY_RECORD_DAY_WEEK_SUCCESS: "ACTION_GET_BODY_RECORD_DAY_WEEK_SUCCESS",
  ACTION_GET_BODY_RECORD_DAY_WEEK_FAIL: "ACTION_GET_BODY_RECORD_DAY_WEEK_FAIL",

  ACTION_GET_BODY_RECORD_DAY_SUCCESS: "ACTION_GET_BODY_RECORD_DAY_SUCCESS",
  ACTION_GET_BODY_RECORD_DAY_FAIL: "ACTION_GET_BODY_RECORD_DAY_FAIL",

  ACTION_GET_BODY_RECORD_MONTH_SUCCESS: "ACTION_GET_BODY_RECORD_MONTH_SUCCESS",
  ACTION_GET_BODY_RECORD_MONTH_FAIL: "ACTION_GET_BODY_RECORD_MONTH_FAIL",

  ACTION_SET_FLAG_SUCCESS: "ACTION_SET_FLAG_SUCCESS",

  // socket
  ACTION_SEND_MESSAGE: "ACTION_SEND_MESSAGE",
  ACTION_ADD_MESSAGE: "ACTION_ADD_MESSAGE",
  ACTION_CLEAR_ALL_MESSAGES: "ACTION_CLEAR_ALL_MESSAGES",

  // listing
  ACTION_GET_LISTINGS_SUCCESS: "ACTION_GET_LISTINGS_SUCCESS",
  ACTION_GET_LISTINGS_FAIL: "ACTION_GET_LISTINGS_FAIL",

  ACTION_ADD_ALL_LISTING_TO_NEW_ANTINFOCOLLECTION_SUCCESS: "ACTION_ADD_ALL_LISTING_TO_NEW_ANTINFOCOLLECTION_SUCCESS",
  ACTION_ADD_ALL_LISTING_TO_NEW_ANTINFOCOLLECTION_FAIL: "ACTION_ADD_ALL_LISTING_TO_NEW_ANTINFOCOLLECTION_FAIL",

  ACTION_GET_LISTING_BY_IDS_SUCCESS: "ACTION_GET_LISTING_BY_IDS_SUCCESS",
  ACTION_GET_LISTING_BY_IDS_FAIL: "ACTION_GET_LISTING_BY_IDS_FAIL",

  ACTION_POST_LISTINGS_SUCCESS: "ACTION_POST_LISTINGS_SUCCESS",
  ACTION_POST_LISTINGS_FAIL: "ACTION_POST_LISTINGS_FAIL",

  ACTION_UPDATE_LISTINGS_HEYDATA_SUCCESS: "ACTION_UPDATE_LISTINGS_HEYDATA_SUCCESS",
  ACTION_UPDATE_LISTINGS_HEYDATA_FAIL: "ACTION_UPDATE_LISTINGS_HEYDATA_FAIL",

  ACTION_GET_LISTINGS_30_DAYS_SOLD_SUCCESS: "ACTION_GET_LISTINGS_30_DAYS_SOLD_SUCCESS",
  ACTION_GET_LISTINGS_30_DAYS_SOLD_FAIL: "ACTION_GET_LISTINGS_30_DAYS_SOLD_FAIL",

  // shop
  ACTION_GET_SHOPS_SUCCESS: "ACTION_GET_SHOPS_SUCCESS",
  ACTION_GET_SHOPS_FAIL: "ACTION_GET_SHOPS_FAIL",

  // task
  ACTION_GET_ALL_TASKS_SUCCESS: "ACTION_GET_ALL_TASKS_SUCCESS",
  ACTION_GET_ALL_TASKS_FAIL: "ACTION_GET_ALL_TASKS_FAIL",

  ACTION_GET_ALL_BOT_TASKS_SUCCESS: "ACTION_GET_ALL_BOT_TASKS_SUCCESS",
  ACTION_GET_ALL_BOT_TASKS_FAIL: "ACTION_GET_ALL_BOT_TASKS_FAIL",

  ACTION_UPDATE_TASK_SUCCESS: "ACTION_UPDATE_TASK_SUCCESS",
  ACTION_UPDATE_TASK_FAIL: "ACTION_UPDATE_TASK_FAIL",

  ACTION_DELETE_TASK_SUCCESS: "ACTION_DELETE_TASK_SUCCESS",
  ACTION_DELETE_TASK_FAIL: "ACTION_DELETE_TASK_FAIL",

  ACTION_GET_LOGS_SUCCESS: "ACTION_GET_LOGS_SUCCESS",
  ACTION_GET_LOGS_FAIL: "ACTION_GET_LOGS_FAIL",

  // antInfoCollection
  ACTION_GET_ALL_ANTINFOCOLLECTIONS_SUCCESS: "ACTION_GET_ALL_ANTINFOCOLLECTIONS_SUCCESS",
  ACTION_GET_ALL_ANTINFOCOLLECTIONS_FAIL: "ACTION_GET_ALL_ANTINFOCOLLECTIONS_FAIL",

  ACTION_ADD_NEW_ANTINFOCOLLECTION_SUCCESS: "ACTION_ADD_NEW_ANTINFOCOLLECTION_SUCCESS",
  ACTION_ADD_NEW_ANTINFOCOLLECTION_FAIL: "ACTION_ADD_NEW_ANTINFOCOLLECTION_FAIL",

  ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_SUCCESS: "ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_SUCCESS",
  ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_FAIL: "ACTION_TOGGLE_LISTING_IN_ANTINFOCOLLECTION_FAIL",

  ACTION_REMOVE_LISTING_FROM_ANTINFOCOLLECTION_SUCCESS: "ACTION_REMOVE_LISTING_FROM_ANTINFOCOLLECTION_SUCCESS",
  ACTION_REMOVE_LISTING_FROM_ANTINFOCOLLECTION_FAIL: "ACTION_REMOVE_LISTING_FROM_ANTINFOCOLLECTION_FAIL",

  ACTION_UPDATE_ANTINFOCOLLECTION_SUCCESS: "ACTION_UPDATE_ANTINFOCOLLECTION_SUCCESS",
  ACTION_UPDATE_ANTINFOCOLLECTION_FAIL: "ACTION_UPDATE_ANTINFOCOLLECTION_FAIL",

  // ant info item
  ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_SUCCESS: "ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_SUCCESS",
  ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_FAIL: "ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_FAIL",

  ACTION_SEARCH_TEAMINFO_BY_RND_CODE_SUCCESS: "ACTION_SEARCH_TEAMINFO_BY_RND_CODE_SUCCESS",
  ACTION_SEARCH_TEAMINFO_BY_RND_CODE_FAIL: "ACTION_SEARCH_TEAMINFO_BY_RND_CODE_FAIL",

  ACTION_ADD_NEW_ANTINFOITEM_SUCCESS: "ACTION_ADD_NEW_ANTINFOITEM_SUCCESS",
  ACTION_ADD_NEW_ANTINFOITEM_FAIL: "ACTION_ADD_NEW_ANTINFOITEM_FAIL",

  // bot
  ACTION_GET_ALL_BOTS_SUCCESS: "ACTION_GET_ALL_BOTS_SUCCESS",
  ACTION_GET_ALL_BOTS_FAIL: "ACTION_GET_ALL_BOTS_FAIL",

  ACTION_GET_LOCAL_DATA_SUCCESS: "ACTION_GET_LOCAL_DATA_SUCCESS",
  ACTION_GET_LOCAL_DATA_FAIL: "ACTION_GET_LOCAL_DATA_FAIL"
};
