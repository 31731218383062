/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Listing from "views/Listing";
import AntInfoCollection from "views/AntInfoCollection";
import AddAntInfo from "views/AddAntInfo";
import AntInfoCollectionDetail from "views/AntInfoCollectionDetail";
import View3D from "views/View3D";
import Shop from "views/Shop";
import Bot from "views/Bot";
import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import TableList from "views/TableList.js";
import Typography from "views/Typography.js";
import Icons from "views/Icons.js";
import Maps from "views/Maps.js";
import Notifications from "views/Notifications.js";
import Upgrade from "views/Upgrade.js";
import View3DLamp from "views/View3DLamp";
import BoxMaker from "views/BoxMaker";
import { getTranslate, LANGUAGE_KEYS } from "utils";
const dashboardRoutes = [
  // {
  //   path: "/listing",
  //   name: getTranslate(LANGUAGE_KEYS.TEXT_LISTING),
  //   icon: "nc-icon nc-puzzle-10",
  //   component: Listing,
  //   layout: "/admin"
  // },
  {
    path: "/antInfoCollection/:id",
    name: getTranslate(LANGUAGE_KEYS.TEXT_ANTINFOCOLLECTION_DETAIL),
    icon: "nc-icon nc-favourite-28",
    component: AntInfoCollectionDetail,
    layout: "/team",
    hidden: true
  },
  {
    path: "/product",
    name: "SẢN XUẤT",
    icon: "nc-icon nc-settings-90",
    component: AntInfoCollection,
    layout: "/team"
  },

  {
    path: "/add-info",
    name: "Add Info",
    icon: "nc-icon nc-simple-add",
    component: AddAntInfo,
    layout: "/team"
  },
  // {
  //   path: "/shop",
  //   name: getTranslate(LANGUAGE_KEYS.TEXT_SHOP),
  //   icon: "nc-icon nc-grid-45",
  //   component: Shop,
  //   layout: "/admin",
  //   // hidden: true
  // },
  // {
  //   path: "/bot",
  //   name: getTranslate(LANGUAGE_KEYS.TEXT_BOT),
  //   icon: "nc-icon nc-android",
  //   component: Bot,
  //   layout: "/admin"
  // },
  // {
  //   path: "/3d-tool",
  //   name: getTranslate(LANGUAGE_KEYS.TEXT_3D_TOOL),
  //   icon: "nc-icon nc-app",
  //   component: View3D,
  //   layout: "/admin",
  //   hidden: !process.env.REACT_APP_3D_TOOL
  // },
  // {
  //   path: "/box-maker",
  //   name: getTranslate(LANGUAGE_KEYS.TEXT_BOX_MAKER),
  //   icon: "nc-icon nc-app",
  //   component: BoxMaker,
  //   layout: "/admin",
  //   hidden: !process.env.REACT_APP_3D_TOOL
  // },
  // {
  //   path: "/3d-lamp",
  //   name:  getTranslate(LANGUAGE_KEYS.TEXT_DESIGN_LAMP_TOOL),
  //   icon: "nc-icon nc-alien-33",
  //   component: View3DLamp,
  //   layout: "/admin",
  //   hidden: !process.env.REACT_APP_3D_TOOL
  // },
  // {
  //   path: "/upgrade",
  //   name: "Upgrade to PRO",
  //   icon: "nc-icon nc-alien-33",
  //   component: Upgrade,
  //   layout: "/admin"
  // },
  {
    path: "/dashboard",
    name: "THỐNG KÊ",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/team"
  },
  // {
  //   path: "/user",
  //   name: getTranslate(LANGUAGE_KEYS.TEXT_USER_PROFILE),
  //   icon: "nc-icon nc-circle-09",
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   icon: "nc-icon nc-notes",
  //   component: TableList,
  //   layout: "/admin",
  //   hidden: !process.env.REACT_APP_LOCALHOST
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "nc-icon nc-paper-2",
  //   component: Typography,
  //   layout: "/admin",
  //   hidden: !process.env.REACT_APP_LOCALHOST
  // },
  {
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-atom",
    component: Icons,
    layout: "/team",
    hidden: !process.env.REACT_APP_LOCALHOST
  }
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "nc-icon nc-pin-3",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "nc-icon nc-bell-55",
  //   component: Notifications,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
