import { TYPES } from "../actions";

const initialState = {
  shops: [],
  totalCount: 0
};

export const shopReducer = (state = initialState, action) => {
  switch (action.type) {

    case TYPES.ACTION_GET_SHOPS_SUCCESS:
      return { ...state, 
        shops: action.payload.data.result, 
        totalCount: action.payload.data.totalCount > -1? action.payload.data.totalCount: state.totalCount };

    case TYPES.ACTION_GET_SHOPS_FAIL:
      return { ...state, shops: [] };
      
    default:
      return state;
  }
};
