/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';
import * as uuid from "uuid";

// import AdminNavbar from "components/Navbars/AdminNavbar";
// import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import LoginForm from 'components/LoginForm';
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import ScrollToTop from "react-scroll-to-top";

import routes from "routes.js";

import sidebarImage from "assets/img/sidebar-3.jpg";
import { Component } from 'react';
import { connect } from "react-redux";
import { getUser } from '../actions';

import smoothscroll from 'smoothscroll-polyfill';
import { CONFIGS } from 'configs';

// Khởi động polyfill
smoothscroll.polyfill();

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalLogin: false,
      color: 'black',
      hasImage: true,
      image: sidebarImage,
      showButton: true
    };

    // this.handleScroll = this.handleScroll.bind(this);
    // this.scrollToTop = this.scrollToTop.bind(this);
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/team") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={uuid.v4()}
          />
        );
      } else {
        return null;
      }
    });
  };

  componentDidMount(){
    document.title = "ANTLAB INFO";
    
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }

    this.props.getUser();
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentWillReceiveProps(props) {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;

    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }

  handleScroll = (e) => {
    e.preventDefault();
    console.log("AAA handleScroll", window.scrollY);

    if (window.scrollY > 100) {
      this.setState({ showButton: true });
    } else {
      this.setState({ showButton: true });
    }
  }

  scrollToTop = (e) => {
    e.preventDefault();
    console.log("AAA scrollToTop", window.scrollY, window)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  render() {
    const isSidebarHidden = process.env.REACT_APP_HIDE_SIDEBAR === '1' && this.props.user && this.props.user.role != CONFIGS.USER_ROLES.ROOT;

    return (
      <>{!this.props.user? <LoginForm />:
        <div className="wrapper">
          
          {!isSidebarHidden && <Sidebar color={this.state.color} image={this.state.hasImage ? this.state.image : ""} routes={routes} />}

          <div className="main-panel" ref={this.mainPanel}>
            {/* <AdminNavbar /> */}
            <div className="content">
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
            {/* {this.state.showButton && (
                <button className="back-to-top" onClick={this.scrollToTop}>
                  Back to Top
                </button>
              )} */}
            {/* <Footer /> */}
          </div>
        </div>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.authenReducer.user
  };
};

export default connect(mapStateToProps, { getUser })(Admin);
