import { TYPES } from "./types";
import socket from '../socket.js'

export const sendMessage = (message) => async (dispatch) => {
  console.log('SEND MESSAGE', message, socket)
  socket.emit('message', message);
};

export const assignJob = (jobData) => async (dispatch) => {
  console.log('assignJob', jobData)
  socket.emit('assign_job', jobData);
};

export const addMessage = (message) => async (dispatch) => {
  console.log("Add message: ", message);
  var message_logs = localStorage.getItem('message_logs')?
                      JSON.parse(localStorage.getItem('message_logs')): []

  message_logs.push(message);
  localStorage.setItem('message_logs', JSON.stringify(message_logs));

  dispatch({
    type: TYPES.ACTION_ADD_MESSAGE,
    payload: message
  });
};

export const clearAllMessages = (message) => async (dispatch) => {
  localStorage.setItem('message_logs', '[]')

  dispatch({
    type: TYPES.ACTION_CLEAR_ALL_MESSAGES,
    payload: message
  });
};

export const socketAction = {
  sendMessage,
  assignJob,
  addMessage,
  clearAllMessages
};