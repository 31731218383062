/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";
import moment from "moment";

export const getListings = (query) => {
  var { itemsPerPage, 
    currentPage, 
    isUpdateQuery, 
    dailyViews, 
    sold30DaysCalculate, 
    revenue30DaysCalculate,
    sold30DaysCount, 
    revenue30DaysCount,
    sortBy, 
    lastUpdate, 
    favoriteRate, 
    taskId, 
    keyword, 
    priceMin, 
    priceMax,
    dateFrom,
    sortOrder,
    antInfoCollectionId,
    jobId,
    exportGGsheet,
    project,
    antInfoCollection_name
    } = query;

  console.log('sortBy', sortBy);

  const lastUpdateMinute = parseInt(lastUpdate) * 24 * 60;
  const itemOffset = (currentPage - 1) * itemsPerPage;

  if (sortOrder < 0) {
    sortBy = '-' + sortBy;
    sortBy = sortBy.replace('--', '');
  }

  console.log('sortBy X', sortBy);

  query = `is_update_query=${isUpdateQuery}`;
  query += `&last_modified_minutes<=${lastUpdateMinute}`

  if (parseFloat(dailyViews) > 0)
    query += `&daily_views>=${dailyViews}`

  if (parseFloat(favoriteRate) > 0)
    query += `&favorite_rate>=${favoriteRate}`

  if (parseFloat(sold30DaysCalculate) > 0)
    query += `&sale_30_days_calculate>=${sold30DaysCalculate}`;

  if (parseFloat(revenue30DaysCalculate) > 0)
    query += `&revenue_30_days_calculate>=${revenue30DaysCalculate}`;

  if (parseFloat(sold30DaysCount) > 0)
    query += `&sale_30_days_count>=${sold30DaysCount}`;

  if (parseFloat(revenue30DaysCount) > 0)
    query += `&revenue_30_days_count>=${revenue30DaysCount}`;

  if (parseFloat(priceMin) > 0)
    query += `&price_usd>=${priceMin}`;

  if (parseFloat(priceMax) > 0)
    query += `&price_usd<=${priceMax}`;

  if (taskId)
    query += `&task_id=${taskId}`;

  if (keyword)
    query += `&keyword=${keyword}`;

  // if (dateFrom)
  //   query += `&created_at>=${dateFrom}`

  if (antInfoCollectionId)
    query += `&collection_id=${antInfoCollectionId}`

  if (exportGGsheet)
    query += `&export_ggsheet=${exportGGsheet}`

  if (project)
    query += `&project=${project}`

  if (antInfoCollection_name)
    query += `&antInfoCollection_name=${antInfoCollection_name}`

  query += `&limit=${itemsPerPage}`
  query += `&skip=${itemOffset}`
  query += `&sort=${sortBy}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/listing/get?${query}`);
};

export const getListingByIds = (listingIds) => {
  var params = listingIds.join(",");
  return API.get(`${process.env.REACT_APP_API_URL}/api/listing/ids/${params}`);
};

export const postListings = (listings) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/listing/`, listings);
};

export const updateListingsHeyData = (listingIds) => {
  var params = listingIds.join(",");
  return API.getHey(`https://vk1ng.com/api/bulk/listings/${params}`, {}, 'CWzz3K6KjnqRLImJ6oa0cDyqNt5HtbyMi1VlCH0E');
};

export const getListing30DaysSold = (listing_id) => {
  var query = `listing_id=${listing_id}`;

  var from_date = moment().subtract(30, 'days').valueOf();
  query += `&date>=${from_date}`;

  query += `&sort=date`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/listing-sold/get?${query}`);
};

export const listingService = {
  getListings,
  getListingByIds,
  postListings,
  updateListingsHeyData,
  getListing30DaysSold
};
