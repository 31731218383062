import moment from "moment";
import { TYPES } from "../actions";

const initialState = {
  antInfoItems: [],
  teamInfoItems: []
};

export const antInfoItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_SUCCESS:
      return { ...state,
        antInfoItems: action.payload.data
      }

    case TYPES.ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_FAIL:
      return { ...state, 
        antInfoItems: []
      }

    case TYPES.ACTION_SEARCH_TEAMINFO_BY_RND_CODE_SUCCESS:
      return { ...state,
        teamInfoItems: action.payload.data
      }

    case TYPES.ACTION_SEARCH_TEAMINFO_BY_RND_CODE_FAIL:
      return { ...state, 
        teamInfoItems: []
      }      
      
    default:
      return state;
  }
};
