// STLViewLamp.js
import React, { Component } from 'react';
import { BsFileBreakFill } from 'react-icons/bs';
import * as THREE from 'three';
import * as THREESTLLoader from '../../libs/three-stl-loader';
import stats from 'three/addons/libs/stats.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLExporter } from 'three/addons/exporters/STLExporter.js';
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { ConvexGeometry } from 'three/addons/geometries/ConvexGeometry.js';
import { TextGeometry } from 'three/addons/geometries/TextGeometry.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';

import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";

import Whammy from 'react-whammy/whammy';
import { BiHide, BiShow } from 'react-icons/bi';
import { GrPowerReset } from "react-icons/gr";
import { BsFillCameraFill, BsFillCameraReelsFill } from 'react-icons/bs';
import { saveAs } from 'file-saver';
import urlExist from "url-exist";

class STLViewLamp extends Component {
  constructor() {
    super();

    this.state = {
      alpha: 85,
      cameraY: 0.6,
      cameraVectorLength: 12,
      selectedModel: null,
      showModelSize: false
    };

    this.prevX = 0;
    this.prevY = 0;
    this.models = [];
    this.meshGeometryArray = [];
    this.meshModelArray = [];

    this.scale = 0.1;
    this.rotateSpeed = 0.5;

    this.stlContainerRef = React.createRef()
    this.translateVector = new THREE.Vector3(0, 0, -40);

    this.dbUrl = 'http://localhost:8080';
  }

  addShadowedLight = ( x, y, z, color, intensity ) => {
      const directionalLight = new THREE.DirectionalLight( color, intensity );
      directionalLight.position.set( x, y, z );
      this.scene.add( directionalLight );

      directionalLight.castShadow = true;

      const d = 1;
      directionalLight.shadow.camera.left = - d;
      directionalLight.shadow.camera.right = d;
      directionalLight.shadow.camera.top = d;
      directionalLight.shadow.camera.bottom = - d;

      directionalLight.shadow.camera.near = 1;
      directionalLight.shadow.camera.far = 4;

      directionalLight.shadow.bias = - 0.002;
  }

  onWindowResize = () => {
    this.interval = setInterval(() => {
      if (this.stlContainerRef.current) {
        let { offsetWidth, offsetHeight } = this.stlContainerRef.current;
        this.camera.aspect = offsetHeight / offsetWidth;
        this.camera.updateProjectionMatrix();
    
        this.renderer.setSize( offsetWidth, offsetHeight );

        clearInterval(this.interval);
      }
    }, 100)
  }

  onMouseWheel = (event) => {
    // this.camera.position.z += event.deltaY/500;
    if (!this.animating) {
      var cameraVectorLength = this.state.cameraVectorLength;
      this.setState({ cameraVectorLength: cameraVectorLength + (event.deltaY/1000) * this.rotateSpeed });
      this.animate();

      if (this.state.recording)
        this.captureFrame();
    }
  }

  animate = () => {
    this.animating = true;
    // requestAnimationFrame( this.animate );
    console.log('ANIMATE');

    this.render3D();
    // this.stats.update();

    this.animating = false;
  }

  render3D = () => {
    this.camera.position.x = Math.cos( this.state.alpha * Math.PI / 180 ) * this.state.cameraVectorLength;
    this.camera.position.z = Math.sin( this.state.alpha * Math.PI / 180) * this.state.cameraVectorLength;
    this.camera.position.y = this.state.cameraY * this.state.cameraVectorLength;

    this.camera.lookAt( this.cameraTarget );
    this.camera.updateProjectionMatrix();

    this.renderer.render( this.scene, this.camera );
  }

  addGroundPlane = () => {
    var imgPath = `${this.dbUrl}/ASSETS/BOX_GAME/imgs/table.jpg`;
    // var imgPath = `${this.dbUrl}/ASSETS/LAMP/SP01/mars-surface-texture-1.jpg`;

    const geometry = new THREE.PlaneGeometry(90, 60);
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(imgPath);
    const material = new THREE.MeshBasicMaterial({ map: texture });

    const plane = new THREE.Mesh(geometry, material);

    plane.rotation.x = - Math.PI / 2;
    plane.position.y = 0;
    plane.position.z = 2.5;
    plane.receiveShadow = true;

    this.holder.add(plane);
    // this.scene.add( plane );
  }

  addBackgrounds = (selectedLampSize) => {
    var backgroundArray = selectedLampSize.backgrounds.map(bg => ({
      name: '',
      imgPath: `${this.dbUrl}/${bg.imgPath}`,
      backgroundWidth: bg.width,
      backgroundHeight: bg.height,
      x1: 0,
      y1: 1,
      x2: 1,
      y2: 0,
      rotation: bg.rotation,
      position: bg.position
    }));

    for (var i=0; i<backgroundArray.length; i++) {
      var bg = backgroundArray[i];
  
      const geometry = new THREE.PlaneGeometry(bg.backgroundWidth, bg.backgroundHeight);
  
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(bg.imgPath);
      texture.magFilter = THREE.LinearFilter;
      texture.minFilter = THREE.LinearMipMapLinearFilter;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      const material = new THREE.MeshLambertMaterial({ map: texture, transparent: true });
  
      var uvArray = geometry.attributes.uv.array;
      var {x1, y1, x2, y2, rotation, position } = bg;

      console.log('uvArray', uvArray);

      uvArray[0] = x1;
      uvArray[1] = y1;
      uvArray[2] = x2;
      uvArray[3] = y1;
      uvArray[4] = x1;
      uvArray[5] = y2;
      uvArray[6] = x2;
      uvArray[7] = y2;
  
      const planeImg = new THREE.Mesh(geometry, material);

      planeImg.rotation.x = rotation[0];
      planeImg.rotation.y = rotation[1];
      planeImg.rotation.z = rotation[2];
      planeImg.position.set(...position);

      planeImg.opacity = 0.05;

      // planeImg.visible = false;
      
      // this.scene.add(planeImg);
      this.holder.add(planeImg);
    }
  }

  addClearBox = () => {
    const geometry = new THREE.BoxGeometry(8.4, 8.7, 8.4, 1);
    const material = new THREE.MeshPhysicalMaterial({
      roughness: 0,
      transmission: 1,
      thickness: 0.3,
    });

    const mesh = new THREE.Mesh(geometry, material);
    this.scene.add(mesh);
  }

  addText = () => {
    const loader = new FontLoader();
    var self = this;
    
    loader.load( 'http://localhost/assets/fonts/helvetiker_regular.typeface.json', font => {
      const textGeometry1 = new TextGeometry(`${this.state.selectedLamp.id} - ${this.state.selectedLamp.name} - Size ${this.state.selectedLampSize.sizeCode}`, {
        size: 0.4,
        height: 0.1,
        curveSegments: 12,
        font
      });
  
      const material1 = new THREE.MeshBasicMaterial({ color: "gold" });
  
      const textMesh1 = new THREE.Mesh(textGeometry1, material1);

      const textSize = new THREE.Vector3();
      const textBoundingBox = new THREE.Box3().setFromObject(textMesh1);
      textBoundingBox.getSize(textSize);

      textMesh1.position.set(-textSize.x / 2, 0.5, 3);
      textMesh1.rotation.set(0, 0, 0);
  
      this.holder.add(textMesh1);

      // ---------
      const textGeometry2 = new TextGeometry(`Size ${this.state.selectedLampSize.sizeCode} (${this.state.selectedLampSize.width}cm x ${this.state.selectedLampSize.height}cm)`, {
        size: 0.75,
        height: 0.1,
        curveSegments: 12,
        font
      });
  
      const material2 = new THREE.MeshBasicMaterial({ color: "gold" });
  
      const textMesh2 = new THREE.Mesh(textGeometry2, material2);
  
      textMesh2.position.set(-5.3, 0.6, 3);
      textMesh2.rotation.set(0, Math.PI/2, 0);
  
      // this.holder.add(textMesh2);
    });
  }

  createLayout = (layoutPoints) => {
    const points = layoutPoints.map(p => new THREE.Vector3(p[0], p[1], p[2]));

    const geometry = new ConvexGeometry( points );
		// const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
    const material = new THREE.MeshPhysicalMaterial({
      roughness: 0,
      transmission: 0.9,
      thickness: 0.3,
      color: '#92B7C9'
    });
		const mesh = new THREE.Mesh( geometry, material );
    // mesh.rotation.x = -Math.PI/2;
    this.holder.add(mesh);
  }

  getCenter = (mesh) => {
    const center = new THREE.Vector3();
    mesh.geometry.computeBoundingBox();
    mesh.geometry.boundingBox.getCenter(center);

    return center;
  }
  
  componentDidMount() {
    var {alpha, cameraY, cameraVectorLength, groupTranslate } = this.props.sceneOptions;
    this.setState({ alpha, cameraY, cameraVectorLength });

    const container = document.getElementById('stl-container');
    let { offsetWidth, offsetHeight } = this.stlContainerRef.current;
    console.log('offset', offsetWidth, offsetHeight);
    this.frames = [];

    this.camera = new THREE.PerspectiveCamera(60, offsetWidth / offsetHeight, 0.1, 1000);

    // this.camera.position.set( 10, 4, 50 );

    this.cameraTarget = new THREE.Vector3( 0, 0, 0 );

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( '#A1CCD1' );

    this.holder = new THREE.Group();
    this.holder.position.set(...groupTranslate);
    this.scene.add(this.holder);
    // this.scene.fog = new THREE.Fog( 0x72645b, 10, 50 );
    
    // Binary files

    var STLLoader = new THREESTLLoader(THREE) // Added THREE
    const loader = new STLLoader();

    var selectedLampSize = this.props.selectedLampSize;
    var selectedLamp = this.props.selectedLamp;

    this.setState({ selectedLampSize, selectedLamp });

    if (selectedLampSize.base) {
      const { width, height, depth, imgPath, position, rotation } = selectedLampSize.base;

      const geometry = new THREE.BoxGeometry(width, height, depth);
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(`${this.dbUrl}/${imgPath}`);
      const material = new THREE.MeshLambertMaterial({ map: texture });
      const base = new THREE.Mesh(geometry, material);
      base.position.set(...position);
      base.rotation.set(...rotation.map(a => a * Math.PI/180));
      this.holder.add(base);
    }

    // this.createLayout(selectedLampSize.layoutPoints);

    this.meshModelArray = selectedLampSize.models.map(m => null);

    selectedLampSize.models.map((model, idx) => {
      loader.load(`${this.dbUrl}/${model.stlPath}`, (geometry) => {
        const material = new THREE.MeshPhongMaterial( { color: model.color, specular: 0x494949, shininess: 200 } );
        let mesh = new THREE.Mesh(geometry, material);

        let originalLength = parseFloat([...model.stlPath.matchAll(/\((\d+)mm\)/g)].map(result => result[1])) / 10; // mm -> cm
        let scale = (model.length / originalLength) * this.scale;
        let scaleX = model.flipX ? -scale: scale;
        let scaleY = model.flipY ? -scale: scale;
        let scaleZ = model.flipZ ? -scale: scale;

        mesh.scale.set( scaleX, scaleY, scaleZ );

        mesh.rotation.set( ...model.rotation.map(a => a * Math.PI/180) );

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        // let pos = new THREE.Vector3(stl.pos[0], stl.pos[1], stl.pos[2] + size.y / (this.scale * 2));

        // if (idx > 0)
        //   pos.z += 10;
          
        const center = new THREE.Vector3();
        geometry.computeBoundingBox();
        geometry.boundingBox.getCenter(center);
        console.log(idx, 'center', center);
        // let v = pos.sub(center);
        // geometry.translate(v.x, v.y, v.z);

        // geometry.translate(this.translateVector.x, this.translateVector.y, this.translateVector.z);

        mesh.position.set(...model.position);

        this.holder.add( mesh );
        // this.models.push(mesh);
        // this.meshModelArray.push(mesh);
        this.meshModelArray[idx] = mesh;

        this.animate();

        // if (idx !=5 && idx != 0)
        this.meshGeometryArray.push(mesh.geometry);
      });
    });

    // Lights

    this.scene.add( new THREE.HemisphereLight( 0x8d7c7c, 0x494966, 3 ) );

    // this.addShadowedLight( 1, 1, 1, 0xffffff, 3.5 );
    this.addShadowedLight( 10, 20, 20, 'white', 2 );
    // this.addShadowedLight( 0.5, 1, - 1, 0xffd500, 3 );

    // axes
    // const axesHelper = new THREE.AxesHelper( 4 );
    // this.scene.add( axesHelper );

    // renderer

    this.renderer = new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( offsetWidth, offsetHeight );
    this.renderer.capabilities.getMaxAnisotropy();
    this.renderer.setClearColor(0x1f1e1c, 1);

    this.renderer.shadowMap.enabled = true;

    container.appendChild( this.renderer.domElement );

    // this.stats = new stats();
    // this.stats.showPanel(0);
    // container.appendChild( this.stats.dom );

    // this.stats.dom.style.position = 'absolute';
    // this.stats.dom.style.top = '10px';
    // this.stats.dom.style.left = '20px';

    // Ground
    this.addGroundPlane();

    // Background
    this.addBackgrounds(selectedLampSize);

    // Cube
    // this.addClearBox();

    this.addText();

    //
    window.addEventListener( 'resize', this.onWindowResize );
    window.addEventListener( 'mousewheel', this.onMouseWheel);

    container.addEventListener( 'mousedown', this.handleDragStart);
    container.addEventListener( 'mousemove', this.handleDrag);
    container.addEventListener( 'mouseup', this.handleDragEnd);

    container.addEventListener("contextmenu", e => e.preventDefault());

    setTimeout(this.animate, 1000);
  }

  handleExport = () => {
    // if (this.meshGeometryArray.length == this.props.stlData.length) {
    // this.meshGeometryArray.map((meshGeometry, idx) => {
    //   try {
    //     const exporter = new STLExporter();
    //     // const mergedGeometry = BufferGeometryUtils.mergeGeometries(meshGeometry);
    //     const mergedMaterial = new THREE.MeshPhongMaterial( { color: "#79BDA1", specular: 0x494949, shininess: 200 } );
    //     const mergedMesh = new THREE.Mesh(meshGeometry, mergedMaterial);
    
    //     const stlData = exporter.parse(mergedMesh);
    
    //     const blob = new Blob([stlData], { type: 'application/octet-stream' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = `stl_${idx}.stl`;
    //     link.click();
    //   } catch {
    //     console.log('Error at ' + idx);
    //   }
    // });

      const exporter = new STLExporter();
      const mergedGeometry = BufferGeometryUtils.mergeGeometries(this.meshGeometryArray);
      const mergedMaterial = new THREE.MeshPhongMaterial( { color: "#79BDA1", specular: 0x494949, shininess: 200 } );
      const mergedMesh = new THREE.Mesh(mergedGeometry, mergedMaterial);
  
      const stlMergedData = exporter.parse(mergedMesh);
  
      const blob = new Blob([stlMergedData], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'stlMerged.stl';
      link.click();
    // }
  }

  handleDragStart = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.mouseDown = true;
    this.prevX = event.clientX;
    this.prevY = event.clientY;
  }

  handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let rightMouseClick = event.which == 3? true: false;

    if (this.mouseDown) {
      const x = event.clientX;
      const y = event.clientY;
      // console.log("drag triggered", x, y);
  
      const dx = x - this.prevX;
      const dy = y - this.prevY;
  
      // console.log('dx dy : ', dx, dy);
  
      if (rightMouseClick && !this.animating) {
        var alpha = this.state.alpha >= 360? this.state.alpha - 360: this.state.alpha;
        if (alpha < 0)
          alpha += 360;

        this.setState({ alpha: alpha + dx * 0.5 * this.rotateSpeed });
        // this.state.alpha += dx * 0.5 * this.rotateSpeed;
        this.animate();

        if (this.state.recording)
          this.captureFrame();
      } else if (!this.animating) {
        var { cameraY, cameraVectorLength } = this.state;
        var d = cameraY * cameraY + cameraVectorLength * cameraVectorLength;

        var newCameraY = cameraY + (dy / 100) * this.rotateSpeed;
        var newCameraVectorLength = Math.sqrt(d - newCameraY * newCameraY);

        this.setState({ cameraY: newCameraY, cameraVectorLength: newCameraVectorLength });
        this.animate(); 

        if (this.state.recording)
          this.captureFrame();
      }
      
      this.prevX = x;
      this.prevY = y;
    }
  }

  handleDragEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.mouseDown = false;
  }

  captureFrame = () => {
    console.log('Caputure Frame');
    const canvas = this.renderer.domElement; // Assuming 'renderer' is your Three.js renderer
    const frameDataURL = canvas.toDataURL(); // Convert canvas to DataURL
    const frameImage = new Image();
    frameImage.src = frameDataURL;
    this.frames.push(frameImage);
  }

  screenshot = (event) => {
    const imageUrl = this.renderer.domElement.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `${this.state.selectedLampSize.id} - ${this.state.selectedLampSize.name}.png`;
    link.click();    
  }

  saveFile = (strData, filename) => {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); //Firefox requires the link to be in the body
        link.download = filename;
        link.href = strData;
        link.click();
        document.body.removeChild(link); //remove the link when done
    } else {
        location.replace(uri);
    }
  }

  recordScreen = () => {
    if (this.creatingVideo)
      return;

    const fps = 30;

    if (!this.state.recording) {
      this.setState({ recording: true });
      // this.interval = setInterval(() => this.captureFrame(), 1000 / fps);
      this.setState({ alpha: 0 });
      this.animate();

      this.interval = setInterval(() => {
        var alpha = this.state.alpha;
        this.setState({ alpha: alpha + 0.3 });

        this.animate();
        this.captureFrame();

        if (alpha > 180) {
          clearInterval(this.interval);
          this.recordScreen();
        }
      }, 100);
    } else {
      this.setState({ recording: false });
      clearInterval(this.interval);

      this.creatingVideo = true;
      
      let { offsetWidth, offsetHeight } = this.stlContainerRef.current;
      const video = new Whammy.Video(fps);

      this.frames.forEach((frame, idx) => {
        // console.log(frame);
        const canvas = document.createElement('canvas');
        canvas.width = 1536;
        canvas.height = 856;
        const context = canvas.getContext('2d');
        context.drawImage(frame, 0, 0);
        video.add(context);
      });
      
      video.compile(false, (output) => {
        const videoUrl = URL.createObjectURL(output);

        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = 'output_video.webm';
        link.click();
      })

      this.creatingVideo = false;
    }
  }

  viewFront = () => {
    this.setState({
      alpha: 90,
      cameraY: 0
    }, this.animate);
  }

  getBBox = (mesh) => {
    var box3 = new THREE.Box3();
    var size = new THREE.Vector3();

    box3.setFromObject( mesh ); // or from mesh, same answer

    box3.getSize( size ); // pass in size so a new Vector3 is not allocated
    
    return size;
  }

  handleSelectModel = (event) => {
    var id = parseInt(event.target.value);
    if (id < 0) {
      this.setState({ selectedModel: null });
      return;
    }

    var selectedModel = this.state.selectedLampSize.models[id];
    var mesh = this.meshModelArray[id];

    this.setState({ 
      selectedModel,
      selectedModelId: id,
      inputLength: selectedModel.length,
      inputRotation: selectedModel.rotation.join(" ,"),
      inputPosition: selectedModel.position.join(" ,"),
      inputColor: selectedModel.color,
      selectedModelBBox: this.getBBox(mesh)
    });

    console.log('handleSelectModel', id);
  }

  handleUpdateModel = (event) => {
    var { inputLength, inputPosition, inputRotation, inputColor } = this.state;

    var length = parseFloat(inputLength);
    var rotation = inputRotation.split(",").map(a => parseFloat(a));
    var position = inputPosition.split(",").map(p => parseFloat(p));
    var color = inputColor;

    var { selectedModelId, selectedModel, selectedLampSize } = this.state;

    var models = selectedLampSize.models;
    models[selectedModelId].length = length;
    models[selectedModelId].rotation = rotation;
    models[selectedModelId].position = position;
    models[selectedModelId].color = color;

    selectedModel.length = length;
    selectedModel.rotation = rotation;
    selectedModel.position = position;
    selectedModel.color = color;

    var mesh = this.meshModelArray[selectedModelId];

    let originalLength = parseFloat([...selectedModel.stlPath.matchAll(/\((\d+)mm\)/g)].map(result => result[1])) / 10; // mm -> cm
    let scale = (selectedModel.length / originalLength) * this.scale;
    let scaleX = selectedModel.flipX ? -scale: scale;
    let scaleY = selectedModel.flipY ? -scale: scale;
    let scaleZ = selectedModel.flipZ ? -scale: scale;

    mesh.scale.set( scaleX, scaleY, scaleZ );

    mesh.rotation.set( ...rotation.map(a => a * Math.PI/180) );
    mesh.position.set(...position);

    mesh.material = new THREE.MeshPhongMaterial( { color: color, specular: 0x494949, shininess: 200 } );

    this.setState({
      selectedModel, 
      selectedLampSize,
      selectedModelBBox: this.getBBox(mesh)
    });

    this.animate();
  }

  handleExportLampConfig = (event) => {  
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(this.state.selectedLampSize, null, 4)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "lamp_config.json";

    link.click();
  }

  handleExportModelSupport = (event) => {
    var modelObj = {};

    this.state.selectedLampSize.models.map(model => {
      if (!modelObj[model.id])
        modelObj[model.id] = [];

      modelObj[model.id].push({
        length: model.length * 10,
        safe_support_y: 0
      })
    })

    var supportData = Object.keys(modelObj).map(id => ({
      id,
      size: modelObj[id]
    }))

    var supportScript = Object.keys(modelObj).map(id => ({
      id,
      length: modelObj[id].map(e => e.length),
      type: "MODEL_RENDER_OPTION_FULL_ROW"
    }))

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify({
        supportData, supportScript
      }, null, 4)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "support_data.json";

    link.click();
  }

  getModelName = (model) => {
    return model.stlPath.split('/').pop().split('.')[1].split('(')[0].trim();
  }

  getModelLocalDiskPath = (model) => {
    var localDisk = "D:\\PRODUCTION_DB";
    var modelFullName = model.stlPath.split('/').pop();
    var modelPath = model.stlPath.replace(modelFullName, "");

    var modelLocalDiskPath = `${localDisk}/${modelPath}`.replaceAll("/", "\\");

    return modelLocalDiskPath;
  }

  checkFIle = async url => {
    return fetch(url, { method: "HEAD" });
  }

  checkSupport = async (model) => {
    var idNumber = parseInt(model.id.split("_")[1]);
    var modelFullName = model.stlPath.split('/').pop();
    var modelPath = model.stlPath.replace(modelFullName, "");

    var supportFilePath = `${this.dbUrl}/${modelPath}${idNumber}/@${model.length * 10}/m01_support.stl`;
    var supportFullRowFilePath = `${this.dbUrl}/${modelPath}${idNumber}/@${model.length * 10}/m01_support_FULL_ROW.stl`;

    var modelLengthKey = [model.id, model.length].join("@");
    var modelLengthFullRowKey = [model.id, model.length, "FULL_ROW"].join("@");

    if (!this.state.modelSupportData || !this.state.modelSupportData[modelLengthKey]) {
      const supportFilePathExists = await urlExist(supportFilePath);

      this.setState({ 
        modelSupportData: {
        ...this.state.modelSupportData,
        [modelLengthKey]: supportFilePathExists? supportFilePath: false
      }});
    }

    if (!this.state.modelSupportData || !this.state.modelSupportData[modelLengthFullRowKey]) {
      const supportFullRowFilePathExists = await urlExist(supportFullRowFilePath);

      this.setState({ 
        modelSupportData: {
        ...this.state.modelSupportData,
        [modelLengthFullRowKey]: supportFullRowFilePathExists? supportFullRowFilePath: false
      }});
    }
  }

  showModelConfig = () => {
    this.setState({ showModalConfigLamp: true });

    this.interval = setInterval(() => {
      this.state.selectedLampSize.models.map(model => this.checkSupport(model));
    }, 3000);
  }

  renderSupportModel = (model) => {
    if (!this.state.modelSupportData)
      return "-";

    var modelLengthKey = [model.id, model.length].join("@");
    var supportPath =  this.state.modelSupportData[modelLengthKey];

    if (supportPath)
      return <a href={supportPath} target="_blank">Supported</a>
    else
      return "No support";
  }

  renderSupportFullRowModel = (model) => {
    if (!this.state.modelSupportData)
      return "-";

    var modelLengthFullRowKey = [model.id, model.length, "FULL_ROW"].join("@");
    var supportFullRowPath =  this.state.modelSupportData[modelLengthFullRowKey];

    if (supportFullRowPath)
      return <a href={supportFullRowPath} target="_blank">Supported</a>
    else
      return "No support";
  }

  hideModalConfigLamp = (event) => {
    this.setState({ showModalConfigLamp: false });
    clearInterval(this.interval);
  }

  render() {
    console.log("render - state", this.state);

    return <>
    <Row style={{height: "100%"}} className="stl-viewer-component">
      <Col md="12" id="stl-container" ref={this.stlContainerRef}>
        <div />  
      </Col>
      
      <div id="toggle-floating-group">
        {this.state.showFloatingGroup?
          <BiHide
            title="Hide Panel"
            onClick={e => this.setState({ showFloatingGroup: false })}
          />:<BiShow
          title="Show Panel"
          onClick={e => this.setState({ showFloatingGroup: true })}
        />}
      </div>
      
      {this.state.showFloatingGroup &&
      <div id="floating-button-group">
        {/* <div>
          <table>
            <tr>
              <td>Alpha</td>
              <td>{this.state.alpha.toFixed(2)}°</td>
            </tr>
            <tr>
              <td>CameraY</td>
              <td>{this.state.cameraY.toFixed(2)} cm</td>
            </tr>
            <tr>
              <td>Zoom</td>
              <td>{this.state.cameraVectorLength.toFixed(2)} cm</td>
            </tr>
          </table>
        </div> */}

        <div className="mt-3">
          {/* <Button
            className="btn-fill pull-right btn-sm"
            id="export-stl"
            type="submit"
            variant="danger"
            onClick={this.handleExport}
          >
            Export All
          </Button> */}

          <BsFillCameraFill 
            class="btn-icon"
            title="Screenshot"
            onClick={this.screenshot}
          />

          <BsFillCameraReelsFill
            onClick={this.recordScreen}
            title={this.state.recording? 'Stop': 'Start'}
            className={`btn-icon ${this.state.recording? 'color-red': ''}`}
          />

          <GrPowerReset 
            class="btn-icon"
            title="Reset view"
            onClick={this.viewFront}
          />

        </div>

        <div>
          <table>
            <tr>
              <th>Model</th>
              <th>Size (mm)</th>
            </tr>
            {this.state.selectedLampSize.models.map(
              (model, idx) => <tr>
                <td>{this.getModelName(model)}</td>
                <td>{model.length * 10}</td>
              </tr>
            )}
          </table>
        </div>

        <div class="mt-3">
          <Button
            className="btn-fill pull-right btn-sm ml-2"
            variant="info"
            onClick={this.showModelConfig}
          >
            Specs
          </Button>

          <Button
            className="btn-fill pull-right btn-sm ml-2"
            variant="primary"
            onClick={this.handleExportLampConfig}
          >
            Save Json
          </Button>  
        </div>

        <div className="update-model mt-3">
          <InputGroup className="mb-3 mt-2">
            <Form.Select
            value={this.state.modelId}
            onChange={this.handleSelectModel}
          >
            <option value="-1">Select Model</option>
            {this.state.selectedLampSize 
            && this.state.selectedLampSize.models.map(
              (model, idx) => <option value={idx}>
                  {this.getModelName(model)}
              </option>)}
            </Form.Select>
          </InputGroup>

          {this.state.selectedModel && <>
            <InputGroup className="mb-3">
              <InputGroup.Text>Length</InputGroup.Text>
              <Form.Control
                value={this.state.inputLength}
                onChange={(e) => this.setState({inputLength: e.target.value})}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Rotation</InputGroup.Text>
              <Form.Control
                value={this.state.inputRotation}
                onChange={(e) => this.setState({inputRotation: e.target.value})}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Position</InputGroup.Text>
              <Form.Control
                value={this.state.inputPosition}
                onChange={(e) => this.setState({inputPosition: e.target.value})}
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text>Color</InputGroup.Text>
              <Form.Control
                value={this.state.inputColor}
                onChange={(e) => this.setState({inputColor: e.target.value})}
              />
            </InputGroup>

            <Form.Control
              defaultValue=""
              value={[
                `Width: ${this.state.selectedModelBBox.x.toFixed(2)}`,
                `Height: ${this.state.selectedModelBBox.y.toFixed(2)}`,
                `Depth: ${this.state.selectedModelBBox.z.toFixed(2)}`].join("\n")}
              placeholder="Paste Shop Links"
              as="textarea"
              rows="3"
            ></Form.Control>  

            <div className="mt-2">
              <Button
                className="btn-fill pull-right btn-sm"
                variant="info"
                onClick={this.handleUpdateModel}
              >
                Update
              </Button>
            </div>
            
          </>}
        </div>
      </div>}

    </Row>

    {this.state.selectedLamp && <>
      <Modal className="modal-config-lamp"
        size="xl"
        show={this.state.showModalConfigLamp}
        onHide={this.hideModalConfigLamp}
      >
        <Modal.Header closeButton>
          <h4>
            Config for lamp {this.state.selectedLamp.id} - {this.state.selectedLamp.name}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <tbody>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Path</th>
                <th>Size</th>
                <th>Supported</th>
                <th>Supported Row</th>
              </tr>
              {this.state.selectedLampSize.models.map(model => <tr>
                <td>{model.id}</td>
                <td>{this.getModelName(model)}</td>
                <td>{this.getModelLocalDiskPath(model)}</td>
                <td>{model.length * 10} mm</td>
                <td>{this.renderSupportModel(model)}</td>
                <td>{this.renderSupportFullRowModel(model)}</td>
              </tr>)}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col md="4">
              <Button
                className="btn-fill pull-right ml-2"
                variant="primary"
                onClick={this.handleExportModelSupport}
              >
                Export
              </Button>  
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>}
  </>
  }
};

export default STLViewLamp;
