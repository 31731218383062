import React, { Component } from "react";
import { connect } from "react-redux";
import * as moment from 'moment'
import {
} from '../actions';
import { CONFIGS } from "../configs";
import { getTranslate, LANGUAGE_KEYS } from "utils";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";

// https://yqnn.github.io/svg-path-editor/

class BoxMaker extends Component {
  constructor() {
    super();
    this.state = {
      width: '100',
      height: '100',
      depth: '100',
      woodDepth1: '5',
      woodDepth2: '3'
    }
  }

  handleChangeWidth = (e) => {
    this.setState({
      width: e.target.value
    })
  }

  handleChangeHeight = (e) => {
    this.setState({
      height: e.target.value
    })
  }

  handleChangeDepth1 = (e) => {
    this.setState({
      depth1: e.target.value
    })
  }

  handleChangeDepth2 = (e) => {
    this.setState({
      depth2: e.target.value
    })
  }

  handleExportSVG = (e) => {
    e.preventDefault();
    console.log(this.state);
  }

  handleExportEPS = (e) => {
    e.preventDefault();
    console.log(this.state);
  }

  renderSVG = () => {
    let svg = `
      <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 10 H 90 V 90 H 10 L 10 10" fill="none" stroke="black"/>
        <path d="M100 100 C 100 50, 200 50, 200 100 S 300 150, 300 100" fill="none" stroke="blue"/>
        <path d="M10 200 Q 100 100, 200 200 T 390 200" fill="none" stroke="red"/>
      </svg>
    `;

    return svg;
  }

  render() {
    return (
      <div id="box-maker-page">
        <h2 className="text-center">{getTranslate(LANGUAGE_KEYS.TEXT_BOX_MAKER)}</h2>
        <Row className="">
          <Col md="3">
            <Row>
              <h4>Input</h4>
            </Row>
            <Row>
              <Col md="12">
                <InputGroup className="mb-3">
                  <InputGroup.Text>Width (mm)</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.width}
                    onChange={this.handleChangeWidth}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputGroup className="mb-3">
                  <InputGroup.Text>Height (mm)</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.height}
                    onChange={this.handleChangeHeight}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputGroup className="mb-3">
                  <InputGroup.Text>Depth (mm)</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.depth}
                    onChange={this.handleChangeDepth}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputGroup className="mb-3">
                  <InputGroup.Text>Wood Depth 1 (mm)</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.woodDepth1}
                    onChange={this.handleChangeDepth1}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <InputGroup className="mb-3">
                  <InputGroup.Text>Wood Depth 2 (mm)</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.woodDepth2}
                    onChange={this.handleChangeDepth2}
                  />
                </InputGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Button
                  className="btn-fill pull-right btn-sm"
                  type="submit"
                  variant="primary"
                  onClick={this.handleExportSVG}
                >
                  Update
                </Button>

                <Button
                  className="btn-fill pull-right ml-3 btn-sm"
                  type="submit"
                  variant="danger"
                  onClick={this.handleExportSVG}
                >
                  Export SVG
                </Button>

                <Button
                  className="btn-fill pull-right ml-3 btn-sm"
                  type="submit"
                  variant="warning"
                  onClick={this.handleExportEPS}
                >
                  Export EPS
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md="9">
            <Row>
              <h4>Preview</h4>
              <div className="svg-preview" dangerouslySetInnerHTML={{ __html: this.renderSVG() }} />
            </Row>

          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  }
}

export default connect(mapStateToProps, {

})(BoxMaker);