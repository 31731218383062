import { TYPES } from "./types";

export const setFlag = (key, value) => async (dispatch) => {
    dispatch({
      type: TYPES.ACTION_SET_FLAG_SUCCESS,
      payload: { key, value },
    });
   
};

export const miscAction = {
  setFlag
};
