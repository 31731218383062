import { TYPES } from "./types";
import { taskService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getAllTasks = () => async (dispatch) => {
  try {
    const result = await taskService.getAllTasks();
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_TASKS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_TASKS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const getBotTasks = (botTaskIds) => async (dispatch) => {  
  try {
    const result = await taskService.getBotTasks(botTaskIds);

    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_BOT_TASKS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_BOT_TASKS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const deleteTask = (taskId) => async (dispatch) => {
  try {
    const result = await taskService.updateTasks([{ _id: taskId, status: -2 }]);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_DELETE_TASK_SUCCESS,
        payload: { taskId },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_DELETE_TASK_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const getLogs = (query) => async (dispatch) => {
  console.log('query', query);
  try {
    const result = await taskService.getLogs(query);
    const data = result.data.data;
    const { task_id } = query;

    const isAppendLog = query.from_time? true: false;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LOGS_SUCCESS,
        payload: { task_id, logs: data, isAppendLog }
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LOGS_FAIL,
        payload: {}
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const taskAction = {
  getAllTasks,
  getLogs
}
