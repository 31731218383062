import { TYPES } from "../actions";

const initialState = {
  actionPostures: [], 
  actionIntensities: [],
  myExercises: [],
  myDiaries: [],
  bodyRecordsHour: [],
  bodyRecordsDay: [],
  bodyRecordsDayWeek: [],
  bodyRecordsMonth: [],
};

export const myRecordReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_GET_EXERCISE_ACTION_CONFIG_SUCCESS:
      console.log("ACTION_GET_EXERCISE_ACTION_CONFIG_SUCCESS", action.payload.data);

      return { ...state, ...action.payload.data };

    case TYPES.ACTION_GET_EXERCISE_ACTION_CONFIG_FAIL:
      return { ...state, actionPostures: [], actionIntensities:[] };

    case TYPES.ACTION_GET_MY_EXERCISES_SUCCESS:
      return { ...state, myExercises: action.payload.data };

    case TYPES.ACTION_GET_MY_EXERCISES_FAIL:
      return { ...state, myExercises: [] };

    case TYPES.ACTION_GET_MY_DIARIES_SUCCESS:
      return { ...state, myDiaries: action.payload.data };

    case TYPES.ACTION_GET_MY_DIARIES_FAIL:
      return { ...state, myDiaries: [] };

    case TYPES.ACTION_GET_BODY_RECORD_HOUR_SUCCESS:
      return { ...state, bodyRecordsHour: action.payload.data };

    case TYPES.ACTION_GET_BODY_RECORD_HOUR_FAIL:
      return { ...state, bodyRecordsHour: [] };

    case TYPES.ACTION_GET_BODY_RECORD_DAY_SUCCESS:
      return { ...state, bodyRecordsDay: action.payload.data };

    case TYPES.ACTION_GET_BODY_RECORD_DAY_FAIL:
      return { ...state, bodyRecordsDay: [] };

    case TYPES.ACTION_GET_BODY_RECORD_DAY_WEEK_SUCCESS:
      return { ...state, bodyRecordsDayWeek: action.payload.data };

    case TYPES.ACTION_GET_BODY_RECORD_DAY_WEEK_FAIL:
      return { ...state, bodyRecordsDayWeek: [] };

    case TYPES.ACTION_GET_BODY_RECORD_MONTH_SUCCESS:
      return { ...state, bodyRecordsMonth: action.payload.data };

    case TYPES.ACTION_GET_BODY_RECORD_MONTH_FAIL:
      return { ...state, bodyRecordsMonth: [] };
      
    default:
      return state;
  }
};
