/* eslint-disable camelcase */
import axios from "axios";

export const getData = (path) => {
  axios.defaults.headers.common = '';
  return axios.get(`${process.env.REACT_APP_LOCAL_DATA_URL}/${path}`);
}

export const localDataService = {
  getData
}