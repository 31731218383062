/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { setFlag } from '../../actions';
import { CONFIGS } from '../../configs';
import { ToastContainer, toast } from 'react-toastify';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import moment from "moment";

class SoldChart extends Component {
  constructor() {
    super();
    this.state = {
      listing30DaysSold: [],
      checkSoldDays: 30,
      totalSold30Days: 0,
      totalSold7Days: 0
    };
  }

  renderOptions = () => {
    var listingSoldData = this.state.listing30DaysSold.slice(-this.state.checkSoldDays);
    var dateData = listingSoldData.map(listingSold => listingSold.date);
    var soldData = listingSoldData.map(listingSold => listingSold.sold);

    return {
      chart: {
        type: 'column'
      },
      title: {
        text: `Sold ${this.state.checkSoldDays} Days Chart`
      },
      xAxis: {
        categories: dateData.map(date => {
          return Highcharts.dateFormat('%b %d', new Date(date).getTime());
        })        
        // crosshair: true
      },
      yAxis: {
        title: {
          text: 'Sold'
        }
      },
      series: [{
        data: soldData,
        name: `Last update: ${moment(this.state.lastUpdatedDate).format("YYYY-MM-DD HH:mm:ss")}`
      }],
      plotOptions: {
        column: {
            dataLabels: {
                enabled: true,
                crop: false,
                overflow: 'none'
            }
        }
      },
    }
  }

  componentWillReceiveProps(props) {
    var { listing30DaysSold } = props;

    if (listing30DaysSold.length > 0) {
      var lastLisingSold = listing30DaysSold[listing30DaysSold.length - 1];
      var lastUpdatedDate = lastLisingSold.updated_at;

      for (var i=0; i<30; i++) {
        var date = moment(lastUpdatedDate).startOf('day').add(12, 'hours').subtract(i, 'days').utc().format("YYYY-MM-DDTHH:mm:SS.sss") + "Z";

        if (listing30DaysSold.filter(listingSold => listingSold.date == date).length == 0)
          listing30DaysSold.splice(listing30DaysSold.length - i, 0, { date, sold: 0})
      }

      var totalSold30Days = 0;
      listing30DaysSold.map(listingSold => totalSold30Days += listingSold.sold);

      var totalSold7Days = 0;
      listing30DaysSold.slice(-7).map(listingSold => totalSold7Days += listingSold.sold);

      this.setState({ lastUpdatedDate, listing30DaysSold, totalSold30Days, totalSold7Days })
    }
  }


  componentDidMount() {
  }

  handleCheckRadio = (e) => {
    console.log(e.target.value);
    this.setState({ checkSoldDays: parseInt(e.target.value) });
  }

  render() {
    return <Container>
      <Row className="">
        <Col md="7">
          {this.props.listing && <div className='text-white listing-tag'>
            <a href={`https://etsy.com/shop/${this.props.listing.shop_name}`} target='_blank' title={`Visit shop ${this.props.listing.shop_name}`}>
              <Badge pill bg="dark">{this.props.listing.shop_name}</Badge>
            </a>
          </div>}
        </Col>
        <Col md="2">
          Total sold: <strong className="text-red">{this.state.checkSoldDays == 30? this.state.totalSold30Days: this.state.totalSold7Days}</strong>
        </Col>
        <Col md="3">
          <Form>
            <div className="listing-sold-days-radio">
              <Form.Group onChange={this.handleCheckRadio}>
                <Form.Check
                  inline
                  label="30 days"
                  name="group1"
                  type='radio'
                  value='30'
                  checked={this.state.checkSoldDays == 30}
                />
                <Form.Check
                  inline
                  label="7 days"
                  name="group1"
                  type='radio'
                  value='7'
                  checked={this.state.checkSoldDays == 7}
                />
              </Form.Group>
            </div>
          </Form>
        </Col>
      </Row>
      <Row>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.renderOptions()}
        />
      </Row>
    </Container>
  }
}

const mapStateToProps = (state) => {
  return {
    listing30DaysSold: state.listingReducer.listing30DaysSold
  };
};

export default connect(mapStateToProps, { setFlag })(SoldChart);