import { TYPES } from "./types";
import { localDataService } from "../services"; 

export const getLocalData = (name, path) => async (dispatch) => {
  try {
    const result = await localDataService.getData(path);
    const data = result.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_LOCAL_DATA_SUCCESS,
        payload: {name, data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_LOCAL_DATA_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const localDataAction = {
  getLocalData
}
