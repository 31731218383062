import { TYPES } from "../actions";

const initialState = {
  flag: {}
};

export const miscReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_SET_FLAG_SUCCESS:
      const { key, value } = action.payload;
      
      return { ...state, flag: {...state.flag, [key]: value } };
    default:
      return state;
  }
};
