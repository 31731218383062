/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import socket from '../socket';
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendMessage, 
  clearAllMessages, 
  addMessage, 
  assignJob, 
  getAllTasks,
  getBotTasks, 
  getLogs, 
  deleteTask,
  getAllBots
 } from '../actions';
import { getTimeNow, formatTime, getDiffMinutesFromNow, getDiffSecondsFromNow, shortenText, getDiffMinutes } from "../utils";
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup,
    Table,
    Modal
  } from "react-bootstrap";
import { Util } from "reactstrap";
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "configs";
import { BiCaretUp, BiCaretDown } from "react-icons/bi";
  

class Bot extends Component {
  constructor() {
    super();
    this.state = {
      botId: 0,
      jobId: 0,
      jobContent: '',
      time_count: 0,

      jobList: [
        "Search Keyword",
        "Search Tag",
        "Listings / Shops",
        "Get Listings Detail",
        "Search By Category",
        "Competitor Analysis",
        "Get Shop Basic Data"
      ],

      orderBy: 'most_relevant',

      listingLinks: localStorage.getItem('listingLinks'),
      shopLinks: localStorage.getItem('shopLinks'),

      listingLinksJob4: localStorage.getItem('listingLinksJob4'),
      listingLinksJob6: localStorage.getItem('listingLinksJob6'),

      showModalTaskLogs: false,
      showModalAssignJob: false,
      taskId: '',
      currentTask: {},
      tasks: [],
      botTasks: [],
      bots: [],
      logs: {},
      forceDisplayLatestTask: false,

      taskStatus: -1,
      botIdLog: -1,

      isShowPingTime: localStorage.getItem('isShowPingTime') == 'false'? false : true,
      isShowCheckinTime: localStorage.getItem('isShowCheckinTime') == 'false'? false : true,
      isShowTaskId: localStorage.getItem('isShowTaskId') == 'false'? false : true,

      isShowServerLogs: localStorage.getItem('isShowServerLogs') == 'false'? false : true,
      isShowLatestLog: localStorage.getItem('isShowLatestLog') == 'false'? false : true,
      isShowSystemInfo: localStorage.getItem('isShowSystemInfo') == 'false'? false : true,
      isCheckWholeShop: localStorage.getItem('isCheckWholeShop') == 'false'? false : true,
      isSelectAllListings: localStorage.getItem('isSelectAllListings') == 'false'? false : true,
      isCheckOldTaskIdJob4: localStorage.getItem('isCheckOldTaskIdJob4') == 'false'? false : true
    };
  }

  handleGetLatestLog = (e) => {
    e.preventDefault();

    if (this.state.taskId && this.state.currentTask 
      && this.state.currentTask.status < 4 && this.state.currentTask.status >= 0) {
        var task_logs = this.state.taskId ? this.state.logs[this.state.taskId]: [];
        var lastLog = task_logs.length > 0? task_logs.slice(-1)[0]: {};
        this.props.getLogs({ task_id: this.state.taskId, from_time: lastLog.time })
    }
    
  }

  handleSelectBot = (e) => {
    var botId = parseInt(e.target.value);

    var botTasks = this.state.tasks.filter(task => task.bot_id == botId);

    if (botTasks.length > 0) {
        var taskStatus = botTasks[0].status;

        if (taskStatus < 4)
          alert(`Bot ${botId} đang chạy. Vui lòng chờ !`);
        else
          this.setState({ botId });
    } else {
      this.setState({ botId });
    }
  }
  
  handleSelectJob = (e) => {
    var jobId = parseInt(e.target.value);
    this.setState({ jobId });
  }

  handleChangeJobContent = (e) => {
    this.setState({ jobContent: e.target.value });
  }

  handleSelectOrderBy = (e) => {
    this.setState({ orderBy: e.target.value });
  }

  handleChangeListingLinks = (e) => {
    this.setState({ listingLinks: e.target.value });
  }

  handleChangeListingLinksJob4 = (e) => {
    this.setState({ listingLinksJob4: e.target.value });
  }

  handleChangeListingLinksJob6 = (e) => {
    this.setState({ listingLinksJob6: e.target.value });
  }

  handleChangeShopLinks = (e) => {
    this.setState({ shopLinks: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ showModalAssignJob: false });

    var { botId, jobId, jobContent, orderBy } = this.state;

    if (jobId == 3) {
      var { listingLinks, shopLinks } = this.state;

      var listingIds = !listingLinks? '': 
        (listingLinks.indexOf('@') < 0 ?
        [...listingLinks.matchAll(/listing\/(\d+)(\/)?/g)].map(result => result[1]).join(","): listingLinks.replace('@', ''))

      var shopNames = !shopLinks? '':
        (shopLinks.indexOf("@") < 0?
        [...shopLinks.matchAll(/shop\/(\w+)(\/)?/g)].map(result => result[1]).join(","): shopLinks.replace('@', ''));

      jobContent = [listingIds, shopNames, this.state.isCheckWholeShop, this.state.isSelectAllListings].join("@")

      localStorage.setItem('listingLinks', this.state.listingLinks);
      localStorage.setItem('shopLinks', this.state.shopLinks);
    } else if (jobId == 4) {
      var { listingLinksJob4, isCheckOldTaskIdJob4 } = this.state;

      var listingIds = listingLinksJob4.indexOf('@') < 0 ?
        [...listingLinksJob4.matchAll(/listing\/(\d+)(\/)?/g)].map(result => result[1]).join(","): listingLinksJob4.replace('@', '');

      jobContent = [listingIds, isCheckOldTaskIdJob4].join('@');

      localStorage.setItem('listingLinksJob4', this.state.listingLinksJob4);
    } else if (jobId == 6) {
      var { listingLinksJob6 } = this.state;

      var listingIds = listingLinksJob6.indexOf('@') < 0 ?
        [...listingLinksJob6.matchAll(/listing\/(\d+)(\/)?/g)].map(result => result[1]).join(","): listingLinksJob6.replace('@', '');

      jobContent = listingIds;
      localStorage.setItem('listingLinksJob6', this.state.listingLinksJob6);
    } else {
      var params = jobContent.split(",");

      if (params.length > 1)
        jobContent = [params[0], orderBy, params[1]].join(",");
      else
        jobContent = [params[0], orderBy].join(",");
    }
    
    this.props.assignJob({
      'bot_id': botId, 
      'job_id': jobId, 
      'job_content': jobContent
    });

    setTimeout(() => {
      this.updateBotRecord();
      this.props.getAllTasks();
      this.setState({ forceDisplayLatestTask: true })
    }, 2000)
  }

  handleDeleteTask = (e) => {
    e.preventDefault();

    this.props.deleteTask(this.state.taskId);

    this.setState({ currentTask: {}, tasks: [], taskId: "", showModalTaskLogs: false });

    setTimeout(() => {
      this.updateBotRecord();
      this.props.getAllTasks();
      this.setState({ forceDisplayLatestTask: true })
    }, 2000)
  }

  handleToggleShowTaskId = (e) => {
    this.setState({
      isShowTaskId: !this.state.isShowTaskId
    })

    localStorage.setItem('isShowTaskId', !this.state.isShowTaskId);
  }

  handleToggleShowPingTime = (e) => {
    this.setState({
      isShowPingTime: !this.state.isShowPingTime
    })

    localStorage.setItem('isShowPingTime', !this.state.isShowPingTime);
  }

  handleToggleShowCheckinTime = (e) => {
    this.setState({
      isShowCheckinTime: !this.state.isShowCheckinTime
    })

    localStorage.setItem('isShowCheckinTime', !this.state.isShowCheckinTime);
  }

  handleToggleCheckWholeShop = (e) => {
    this.setState({
      isCheckWholeShop: !this.state.isCheckWholeShop
    })

    localStorage.setItem('isCheckWholeShop', !this.state.isCheckWholeShop);
  }

  handleToggleSelectAllListings = (e) => {
    this.setState({
      isSelectAllListings: !this.state.isSelectAllListings
    })

    localStorage.setItem('isSelectAllListings', !this.state.isSelectAllListings);
  }

  handleToggleShowServerLogs = (e) => {
    this.setState({
      isShowServerLogs: !this.state.isShowServerLogs
    })

    localStorage.setItem('isShowServerLogs', !this.state.isShowServerLogs);
  }

  handleToggleShowLatestLog = (e) => {
    this.setState({
      isShowLatestLog: !this.state.isShowLatestLog
    })

    localStorage.setItem('isShowLatestLog', !this.state.isShowLatestLog);
  }

  handleToggleShowSystemInfo = (e) => {
    this.setState({
      isShowSystemInfo: !this.state.isShowSystemInfo
    })

    localStorage.setItem('isShowSystemInfo', !this.state.isShowSystemInfo);
  }

  handleToggleCheckOldTaskIdJob4 = (e) => {
    this.setState({
      isCheckOldTaskIdJob4: !this.state.isCheckOldTaskIdJob4
    })

    localStorage.setItem('isCheckOldTaskIdJob4', !this.state.isCheckOldTaskIdJob4);
  }

  handleClearLog = (e) => {
    e.preventDefault();

    this.props.clearAllMessages()
  }

  handleSelectStatus = (e) => {
    this.setState({ taskStatus: parseInt(e.target.value) });
  };

  handleSelectTask = (e, task_id) => {
    console.log("handleSelectTask", e, task_id);

    if (e)
      e.preventDefault();

    var taskId = e? e.target.value: task_id;

    if (taskId) {
      var currentTask = this.state.tasks.filter(task => task._id == taskId)[0];

      this.setState({ taskId, currentTask, showModalTaskLogs: true })
  
      this.props.getLogs({ task_id: taskId })

      // clearInterval(this.intervalId);

      // this.intervalId = setInterval(() => {
      //   if (this.state.taskId && this.state.currentTask 
      //     && this.state.currentTask.status < 4 && this.state.currentTask.status >= 0) {
      //       console.log("setInterval");
      //       var task_logs = this.state.taskId ? this.state.logs[this.state.taskId]: [];
      //       var lastLog = task_logs.length > 0? task_logs.slice(-1)[0]: {};
      //       this.props.getLogs({ task_id: this.state.taskId, from_time: lastLog.time })
      //     }
      // }, 60000)
    }
  }

  handleSelectBotLog = (e) => {
    e.preventDefault()
    var botIdLog = parseInt(e.target.value);
    this.setState({ botIdLog });
    
    // var tasks = this.state.tasks.filter(task => task.bot_id == botIdLog);

    // if (tasks.length > 0)
    //   this.handleSelectTask(null, tasks[0]._id);
  };

  updateBotRecord = (propsBots) => {
    if (!propsBots)
      this.props.getAllBots();

    var bots = propsBots? propsBots: this.state.bots;
    var botTaskIds = bots.filter(bot => bot.current_task_id).map(bot => bot.current_task_id).join(',');

    console.log('bots', bots);

    if (botTaskIds) {
      console.log('updateBotRecord', botTaskIds);
      this.props.getBotTasks(botTaskIds);
    }

    // check scheduled tasks
    var scheduledTasks = this.state.tasks.filter(task => task.bot_id == -1 && task.status == CONFIGS.TASK_STATUS.SCHEDULE);
    
    var availableBots = this.state.bots.filter(bot => {
      var currentTask = this.state.tasks.filter(task => task._id == bot.current_task_id);

      if (currentTask.length == 0)
        return false;
      
      currentTask = currentTask[0];

      return (currentTask.step >= 3 && currentTask.status == CONFIGS.TASK_STATUS.FINISH);
    });

    scheduledTasks.reverse().map((scheduleTask, idx) => {
      if (idx < availableBots.length) {
        var bot = availableBots[idx];
        var task = {...scheduleTask, bot_id: bot.bot_id };

        this.props.assignJob({
          'bot_id': task.bot_id,
          'job_id': task.job_id, 
          'job_content': task.task_content,
          'task': task
        });
      }
      
    })

  }

  componentDidMount() {
    console.log('BOT - componentDidMount');

    socket.on('message', (message) => { 
      console.log(message);
      this.props.addMessage(getTimeNow() + " From server : " + message);
    })

    this.props.getAllTasks();
    this.setState({ forceDisplayLatestTask: true })

    this.intervalId2 = setInterval(() => {
      var { time_count } = this.state;
      time_count = (time_count) % 10;

      this.setState({ time_count: time_count + 1 })

      if (time_count == 0)
        this.updateBotRecord();

    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalId2);
  }

  componentWillReceiveProps(props) {
    if (props.bots && props.bots.length > this.state.bots.length)
      this.updateBotRecord(props.bots);

    this.setState({
      logs: props.logs,
      tasks: props.tasks,
      botTasks: props.botTasks,
      bots: props.bots
    });

    if (props.tasks.length > this.state.tasks.length && this.state.forceDisplayLatestTask) {
      console.log('BOT - componentWillReceiveProps')
      var currentTask = props.tasks[0]

      this.setState({
        taskId: currentTask._id,
        forceDisplayLatestTask: false,
        currentTask
      }, () => {
        // this.props.getLogs({ task_id: currentTask._id })
        // clearInterval(this.intervalId);
  
        // this.intervalId = setInterval(() => {
        //   if (this.state.taskId && this.state.currentTask 
        //     && this.state.currentTask.status < 4 && this.state.currentTask.status >= 0) {
        //       var task_logs = this.state.taskId ? this.state.logs[this.state.taskId]: [];
        //       var lastLog = task_logs.length > 0? task_logs.slice(-1)[0]: {};
        //       this.props.getLogs({ task_id: this.state.taskId, from_time: lastLog.time })
        //     }
        // }, 60000)
      })
    }
  }

  renderTaskStatus(task) {
    var { step, status } = task;
    var el = <></>;

    var dotStr = Array(this.state.time_count % 5).fill('.').join('');

    if (status == 1 || step == 1)
      el = <Badge pill bg="info">Running {dotStr}</Badge>;
    else if (status == 2)
      el = <Badge pill bg="success">Finish</Badge>;
    else
      el = <Badge pill bg="danger">Error!</Badge>;

    return el;
  }

  handleViewTaskLogs(taskId) {
    this.handleSelectTask(null, taskId);

    // var currentTask = this.state.tasks.filter(task => task._id == taskId)[0];

    this.setState({
      showModalTaskLogs: true
      // taskId,
      // currentTask
    });
  }

  assignJobForBot(botId) {
    this.setState({ botId, showModalAssignJob: true })
  }

  handleCopyText(text) {
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }

    toast.success(`Copied to clipboard : ${text}`, {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true
    });
  }

  renderCustomBadge(style, className, text) {
    return <Badge key={text} pill 
      bg={style}
      className={className}
      onClick={() => this.handleCopyText(text)}
      title="Click to copy"
    >
      {text}
    </Badge>
  }

  render() {
    var taskLogs = this.state.taskId ? this.state.logs[this.state.taskId]: [];
    taskLogs = taskLogs? taskLogs: [];
    taskLogs = taskLogs && this.state.isShowLatestLog? taskLogs.concat().reverse(): taskLogs;
    taskLogs = this.state.isShowSystemInfo ? taskLogs: taskLogs.filter(log => log.level != 9);
    var currentTask = this.state.currentTask;

    var currentTasks = this.state.tasks
      .filter(task => this.state.taskStatus == -1? true
        :task.status == this.state.taskStatus)
      .filter(task => {
        if (this.state.botIdLog < 0)
          return true;

        return task.bot_id == this.state.botIdLog;
      })

    var currentBotTasks = this.state.bots.map(bot => {
        var task = bot.current_task_id ? 
          (this.state.botTasks.filter(task => task._id == bot.current_task_id)?
          this.state.botTasks.filter(task => task._id == bot.current_task_id)[0]: null) : null;

        return task;
      });

    var scheduledTasks = currentTasks.filter(task => task.bot_id == -1).reverse();
    currentTasks = currentTasks.filter(task => task.bot_id >= 0) //&& task.status != CONFIGS.TASK_STATUS.IN_PROGESS)
                    .sort((a, b) => -moment(a.finish_time | a.start_time).diff(b.finish_time | b.start_time));

    // console.log("currentBotTasks", currentBotTasks, this.state.botTasks);

    return (
    <>
      <Container fluid id="bot-page">
        
        <Row className="bot-report">
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Running Tasks</Card.Title>
                <p className="card-category">
                  Automatically update in {(10 - this.state.time_count) % 10 + 1} seconds
                </p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">Job</th>
                      <th className="border-0">Task</th>
                      {this.state.isShowTaskId && <th className="border-0">Task ID</th>}
                      <th className="border-0">Step</th>
                      <th className="border-0">Listing</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Progress</th>
                      <th className="border-0">Running time</th>
                      <th className="border-0">Last Activity</th>
                      {this.state.isShowPingTime && <th className="border-0">Last Ping</th>}
                      {this.state.isShowCheckinTime && <th className="border-0">Last Checkin</th>}
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bots.map((bot, idx) => <tr>
                      <td>Bot {bot.bot_id}</td>

                      {currentBotTasks[idx]? <>
                        <td>{this.state.jobList[currentBotTasks[idx].job_id - 1]}</td>
                        <td
                          className="cursor-pointer"
                          title="Click to view logs"
                          onClick={e => this.handleViewTaskLogs(bot.current_task_id)}
                        >
                          <Badge pill bg="info">{shortenText(currentBotTasks[idx].task_content, 30)}</Badge>
                        </td>

                        {this.state.isShowTaskId && <td
                          className="cursor-pointer"
                          title="Click to view logs"
                          onClick={e => this.handleViewTaskLogs(bot.current_task_id)}
                        ><Badge pill bg="info">{currentBotTasks[idx]._id}</Badge></td>}

                        <td>{currentBotTasks[idx].step}/{currentBotTasks[idx].total_step}</td>
                        <td>{currentBotTasks[idx].listing_public_count|0}/{currentBotTasks[idx].listing_count|0}</td>
                        <td>{this.renderTaskStatus(currentBotTasks[idx])}</td>
                        <td>{currentBotTasks[idx].progress? currentBotTasks[idx].progress.toFixed(2): 0}%</td>
                        <td>{getDiffMinutesFromNow(currentBotTasks[idx].start_time, true)}</td>
                        <td>{moment(currentBotTasks[idx].updated_time).fromNow()}</td>
                      </>:<>
                        <td>-</td>
                        <td>-</td>
                        {this.state.isShowTaskId && <td>-</td>}
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </>}
                      
                      {this.state.isShowPingTime && <td>{getDiffSecondsFromNow(bot.ping_time)}</td>}
                      {this.state.isShowCheckinTime && <td>{getDiffMinutesFromNow(bot.checkin_time)}</td>}

                      {/* <td>{((currentBotTasks[idx] && currentBotTasks[idx]['step'] == currentBotTasks[idx]['total_step'] 
                      && currentBotTasks[idx]['status'] == 2) || !currentBotTasks[idx]) && */}
                      <td>{
                        <Badge pill bg="danger" className="cursor-pointer"
                        onClick={e => this.assignJobForBot(bot.bot_id)}
                      >Assign Job</Badge>}
                      </td>
                    </tr>)}
                  </tbody>
                </Table>

                <Row>
                  <Col md="2">
                    <Form>
                      <Form.Check
                        className="ml-3"
                        type='switch'
                        label="Task ID"
                        checked={this.state.isShowTaskId}
                        onChange={this.handleToggleShowTaskId}
                      />
                    </Form>
                  </Col>

                  <Col md="2" className="">
                    <Form>
                      <Form.Check
                        type='switch'
                        label="Ping Time"
                        checked={this.state.isShowPingTime}
                        onChange={this.handleToggleShowPingTime}
                      />
                    </Form>
                  </Col>

                  <Col md="2" className="">
                    <Form>
                      <Form.Check
                        type='switch'
                        label="Checkin Time"
                        checked={this.state.isShowCheckinTime}
                        onChange={this.handleToggleShowCheckinTime}
                      />
                    </Form>
                  </Col>

                  <Col md="4">
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>

        <>
        {/* <Row className="pt-2 filter-group">
          <Col md="2">
            <Form>
              <Form.Check
                type='switch'
                label="Show Server Log"
                checked={this.state.isShowServerLogs}
                onChange={this.handleToggleShowServerLogs}
              />
            </Form>
          </Col>
        </Row> */}

        {/* {this.state.isShowServerLogs && <>
          <Row className="message-log mb-4">
            {this.props.messages.concat().reverse().map((msg, index) => (
              <p className="text-muted" key={index}>
                {msg}
              </p>
            ))}
            
          </Row>

          <div>
            <Button
              className="btn-sm pull-right btn-clear-log"
              type="button"
              variant="info"
              onClick={this.handleClearLog}
            >
              Clear log
            </Button>
          </div>
        </>}  */}
        </>

        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="10">
                    <Card.Title as="h4">Scheduled Tasks</Card.Title>
                  </Col>
                  <Col md="2" style={{textAlign: "right"}}>
                    <Button
                      className="btn-fill pull-right btn-sm mr-3"
                      type="submit"
                      variant="primary"
                      onClick={e => this.assignJobForBot(-1)}
                    >
                      Schedule Task
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">#</th>
                      <th className="border-0">Job</th>
                      <th className="border-0">Task</th>
                      <th className="border-0">Start Time</th>
                      <th className="border-0">Waiting Time</th>
                      <th className="border-0">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {scheduledTasks.map((task, idx) => <tr>
                      <td>{idx+1}</td>
                      <td>{this.state.jobList[task.job_id - 1]}</td>
                      <td><Badge pill bg="info" className="cursor-pointer"
                        onClick={e => this.handleViewTaskLogs(task._id)}
                      >{shortenText(task.task_content, 30)}</Badge></td>
                      <td>{moment(task.start_time).format("YY-MM-DD HH:mm:ss")}</td>
                      <td>{getDiffMinutesFromNow(task.start_time)}</td>
                      <td>
                        <BiCaretUp />
                        <BiCaretDown />
                      </td>
                    </tr>)}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        
        <Row className="task-report">
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Finished Tasks</Card.Title>
                <p className="card-category">
                </p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Row>
                  <Col md="2">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">Bot</InputGroup.Text>
                      <Form.Select aria-label="Bot"
                        value={this.state.botIdLog} 
                        onChange={this.handleSelectBotLog}
                      >
                        <option value="-1">All</option>
                        <option value="0">Bot 0</option>
                        <option value="1">Bot 1</option>
                        <option value="2">Bot 2</option>
                        <option value="3">Bot 3</option>
                        <option value="4">Bot 4</option>
                        <option value="5">Bot 5</option>
                    </Form.Select>
                    </InputGroup>
                  </Col>

                  <Col md="2">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">Status</InputGroup.Text>
                      <Form.Select aria-label="Bot"
                        value={this.state.task_status} 
                        onChange={this.handleSelectStatus}
                      >
                        <option value="-1">All</option>
                        {/* <option value="-1">Error</option> */}
                        <option value="0">Start</option>
                        <option value="1">Finish Step 1</option>
                        <option value="2">Finish Step 2</option>
                        <option value="3">Finish Step 3</option>
                        <option value="4">Finish Step 4</option>
                      </Form.Select>
                    </InputGroup>
                  </Col>

                  <Col md="6">
                    <InputGroup className="mb-3">
                      <InputGroup.Text id="basic-addon1">Task</InputGroup.Text>
                      <Form.Select aria-label="Job"
                      value={this.state.taskId}
                      onChange={this.handleSelectTask}
                    >
                      <option value="">All</option>
                      {currentTasks
                      .map(task => (
                        <option value={task._id}>
                          <p className="text-capitalize">[{formatTime(task.start_time, "YYYY/MM/DD HH:mm:ss")}] {shortenText(task.task_content, 30)} - (Bot {task.bot_id})</p>
                          </option>
                      ))}
                      </Form.Select>
                    </InputGroup>
                  </Col>
                </Row>
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Name</th>
                      <th className="border-0">Bot</th>
                      <th className="border-0">Step</th>
                      <th className="border-0">Listing</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Progress</th>
                      <th className="border-0">Start Time</th>
                      <th className="border-0">Finish Time</th>
                      <th className="border-0">Running Time</th>
                      {/* <th className="border-0">Last Checkin</th>
                      <th className="border-0">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentTasks.map(task => <tr>
                      
                      <td><Badge pill bg="info" className="cursor-pointer"
                        onClick={e => this.handleViewTaskLogs(task._id)}
                      >{shortenText(task.task_content, 30)}</Badge></td>
                      <td>Bot {task.bot_id}</td>
                      <td>{task.step}/{task.total_step}</td>
                      <td>{task.listing_public_count}/{task.listing_count}</td>
                      <td>{this.renderTaskStatus(task)}</td>
                      <td>{task.progress? task.progress.toFixed(2): 0}%</td>
                      <td>{moment(task.start_time).format("YY-MM-DD HH:mm:ss")}</td>
                      <td>{moment(task.finish_time).format("YY-MM-DD HH:mm:ss")}</td>
                      <td>{getDiffMinutes(task.start_time, task.finish_time, true)}</td>
                      {/* <td>{}</td>
                      <td></td> */}
                    </tr>)}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          {/* <div className='text-white mb-4'>
            {currentTask._id && <>
              <Badge pill bg="danger">{currentTask._id}</Badge>
              <Badge pill bg="danger" className='ml-3'>Content: {currentTask.task_content}</Badge>
              <Badge pill bg="danger" className='ml-3'>Bot ID: {currentTask.bot_id}</Badge>              
              <Badge pill bg="danger" className='ml-3'>Job ID: {currentTask.job_id}</Badge>
              <Badge pill bg="danger" className='ml-3'>Status: {currentTask.status}</Badge>
              <Badge pill bg="danger" className='ml-3'>Start: {formatTime(currentTask.start_time, "YYYY/MM/DD HH:mm:ss")}</Badge>
              
              {currentTask.status == 4 && <>
                <Badge pill bg="danger" className='ml-3'>Finish: {formatTime(currentTask.finish_time, "YYYY/MM/DD HH:mm:ss")}</Badge>
                <Badge pill bg="danger" className='ml-3'>Result: {currentTask.result_count} listings</Badge>
              </>}
            </>}
          </div> */}
        </Row>
      </Container>

      <Modal size="xl" className="modal-task-logs"
        show={this.state.showModalTaskLogs} 
        onHide={e => this.setState({ showModalTaskLogs: false })}>
        <Modal.Header closeButton>
          {/* <Modal.Title as="h4">Task Log</Modal.Title> */}
          <strong>Task {shortenText(currentTask.task_content, 30)}</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md="2">
              <Form>
                <Form.Check
                  type='switch'
                  label="Latest Log"
                  checked={this.state.isShowLatestLog}
                  onChange={this.handleToggleShowLatestLog}
                />
              </Form>
            </Col>

            <Col md="2">
              <Form>
                <Form.Check
                  type='switch'
                  label="System Info"
                  checked={this.state.isShowSystemInfo}
                  onChange={this.handleToggleShowSystemInfo}
                />
              </Form>
            </Col>

            <Col md="6">
            </Col>

            <Col md="2">
            {(this.state.currentTask.status < 3 || localStorage.getItem('dev_mode') == 'true') && <>
              <Button
                className="btn-fill pull-right btn-sm"
                type="submit"
                variant="danger"
                onClick={this.handleDeleteTask}
              >
                Delete
              </Button>
            </>}
            {this.state.currentTask.status < 4 && <Button
                className="ml-3 btn-fill pull-right btn-sm"
                type="submit"
                variant="primary"
                onClick={this.handleGetLatestLog}
              >
                Reload
            </Button>}
            </Col>
          </Row>
          <div className='text-white mb-4'>
            {currentTask._id && <>
              <Badge pill bg="danger">{currentTask._id}</Badge>
              {this.renderCustomBadge("danger", "ml-3", shortenText(currentTask.task_content, 30))}
              <Badge pill bg="danger" className='ml-3'>Bot ID: {currentTask.bot_id}</Badge>              
              <Badge pill bg="danger" className='ml-3'>Job ID: {currentTask.job_id}</Badge>
              <Badge pill bg="danger" className='ml-3'>Status: {currentTask.status}</Badge>
              <Badge pill bg="danger" className='ml-3'>Start: {formatTime(currentTask.start_time, "YYYY/MM/DD HH:mm:ss")}</Badge>
              
              {currentTask.status == 4 && <>
                <Badge pill bg="danger" className='ml-3'>Finish: {formatTime(currentTask.finish_time, "YYYY/MM/DD HH:mm:ss")}</Badge>
                <Badge pill bg="danger" className='ml-3'>Result: {currentTask.result_count} listings</Badge>
              </>}
            </>}
          </div>

          <div className="modal-task-logs-list">
          {taskLogs && taskLogs.map((log, index) => (
            <p className={`${log.level == 1 && 'font-weight-bold text-success'} ${log.level == 9 && 'font-weight-italic text-info'}`} key={index}>
              [{formatTime(log.time, "YYYY/MM/DD HH:mm:ss")}] {log.message}
            </p>
          ))}
          {
            taskLogs.length == 0 && <div class="loading">
            <FerrisWheelSpinner loading={true}　color="#17a2b8" />
          </div>
          }
        </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" className="float-right" size="sm" onClick={e => this.setState({ showModalTaskLogs: false })}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>

      <Modal size="xl" className="modal-assign-job"
        show={this.state.showModalAssignJob} 
        onHide={e => this.setState({ showModalAssignJob: false })}>
        <Modal.Header closeButton>{
          this.state.botId >= 0?
          <strong>Assign job for Bot {this.state.botId}</strong>
          :<strong>Schedule Job</strong>
        }
          
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Row>
              <Col md="4">
                <Form.Select aria-label="Job"
                    value={this.state.jobId} 
                    onChange={this.handleSelectJob}
                >
                  <option>Select Job</option>
                  <option value="1">Search Keyword</option>
                  <option value="2">Search Tag</option>
                  <option value="5">Search By Category</option>
                  <option value="3">Listings / Shops</option>
                  <option value="4">Get Listings Detail</option>
                  <option value="6">Competitor Analysis</option>
                </Form.Select>
              </Col>

              {(this.state.jobId == 1 || this.state.jobId == 2 || this.state.jobId == 5 || this.state.jobId == 6)? <>
                <Col md="4">
                  <Form.Control
                    defaultValue=""
                    value={this.state.jobContent}
                    placeholder="Enter value"
                    type="text"
                    onChange={this.handleChangeJobContent}
                    disabled={this.state.jobId == 3 || this.state.jobId == 4 || this.state.jobId == 6}
                  ></Form.Control>
                </Col>

                <Col md="2">
                  <Form.Select aria-label="Job"
                      value={this.state.orderBy} 
                      onChange={this.handleSelectOrderBy}
                  >
                    <option>Order By</option>
                    <option value='most_relevant'>Most relevant</option>
                    <option value='price_asc'>Price ⬆</option>
                    <option value='price_desc'>Price ⬇</option>
                    <option value='highest_reviews'>Reviews</option>
                    <option value='date_desc'>Recent</option>
                  </Form.Select>
                </Col>
              </>: <Col md="6"></Col>}
              
              <Col md="2">
                <Button
                  className="btn-fill pull-right btn-sm"
                  type="submit"
                  variant="danger"
                  onClick={this.handleSubmit}
                >
                  Send Command
                </Button>
              </Col>
            </Row>
            {this.state.jobId == 3 && <>
              <Row className="mt-2">
                <Col md="6">
                  <Form.Control
                    defaultValue=""
                    value={this.state.listingLinks}
                    placeholder="Paste Listing Links"
                    as="textarea"
                    onChange={this.handleChangeListingLinks}
                    rows="5"
                  ></Form.Control>
                </Col>

                <Col md="6">
                  <Form.Control
                    defaultValue=""
                    value={this.state.shopLinks}
                    placeholder="Paste Shop Links"
                    as="textarea"
                    onChange={this.handleChangeShopLinks}
                    rows="5"
                  ></Form.Control>  
                </Col>
              </Row>

              <Row>
                {this.state.listingLinks && 
                  <Col md="2">
                    <Form className="check-whole-shop pt-2 ">
                      <Form.Check
                        type='switch'
                        label="Check Shop"
                        checked={this.state.isCheckWholeShop}
                        onChange={this.handleToggleCheckWholeShop}
                      />
                    </Form>
                  </Col>
                }

                <Col md="2">
                  <Form className="check-whole-shop pt-2 ">
                    <Form.Check
                      type='switch'
                      label="All Listings"
                      checked={this.state.isSelectAllListings}
                      onChange={this.handleToggleSelectAllListings}
                    />
                  </Form>
                </Col>
              </Row>
            </>}

            {this.state.jobId == 4 && <>
              <Row className="mt-2 mb-2">
                <Col md="12">
                  <Form.Control
                    defaultValue=""
                    value={this.state.listingLinksJob4}
                    placeholder="Paste Listing Links"
                    as="textarea"
                    onChange={this.handleChangeListingLinksJob4}
                    rows="5"
                  ></Form.Control>
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <Form className="check-whole-shop pt-2 ">
                    <Form.Check
                      type='switch'
                      label="Override Task ID"
                      checked={this.state.isCheckOldTaskIdJob4}
                      onChange={this.handleToggleCheckOldTaskIdJob4}
                    />
                  </Form>
                </Col>
              </Row>
            </>}

            {this.state.jobId == 6 && <>
              <Row className="mt-2 mb-2">
                <Col md="12">
                  <Form.Control
                    defaultValue=""
                    value={this.state.listingLinksJob6}
                    placeholder="Paste Listing Links"
                    as="textarea"
                    onChange={this.handleChangeListingLinksJob6}
                    rows="5"
                  ></Form.Control>
                </Col>
              </Row>
            </>}
          </Form.Group>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" className="float-right" size="sm" onClick={e => this.setState({ showModalAssignJob: false })}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.socketReducer.messages,
    tasks: state.taskReducer.tasks,
    botTasks: state.taskReducer.botTasks,
    logs: state.taskReducer.logs,
    bots: state.botReducer.bots
  };
}

export default connect(mapStateToProps, { 
  assignJob, 
  addMessage, 
  clearAllMessages, 
  getAllTasks, 
  getBotTasks,
  getLogs, 
  deleteTask,
  getAllBots
 })(Bot);