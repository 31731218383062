import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as uuid from "uuid";

class RedirectComponent extends Component {
    componentDidMount() {
        const { prefix, sitename } = this.props.match.params;

        const fetchRedirectConfig = () => {
            fetch(`/redirectConfig.json?t=${uuid.v4()}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(mappingUrls => {
                    console.log("AAA", mappingUrls);
                    const url = mappingUrls[prefix]?.[sitename];
                    if (url) {
                        document.title = 'Loading...';
                        window.location.href = url;
                    } else {
                        console.error('URL not found');
                    }
                })
                .catch(error => {
                    console.error('Error fetching redirect configuration:', error);
                });
        };

        fetchRedirectConfig();
    }

    render() {
        const { sitename } = this.props.match.params;
        return <div>Loading QR ...</div>;
    }
}

export default withRouter(RedirectComponent);
