import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import { 
} from '../actions';
import Pagination from "../components/Pagination";
import ScrollUpButton from 'react-scroll-up-button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import queryString from 'query-string';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup,
    Collapse,
    Modal,
    Table
  } from "react-bootstrap";

import STLViewerLamp from '../components/STLViewerLamp';
import pokemonData from '../assets/pokemon_data.json';
import { getLocalData } from "../actions";

// lamp data

// in cm
const sceneOptions = {
  alpha: 90,
  cameraY: 0,
  cameraVectorLength: 18,
  groupTranslate: [0, -2, 0]
};

class View3DLamp extends Component {
  constructor() {
    super();
    this.state = {
      lampData: null
    }
  }

  componentDidMount(){
    this.props.getLocalData('lampData', 'CONFIGS/lamps.json');
  }

  componentWillReceiveProps(props) {
    this.setState({ lampData: props.lampData });
  }

  handleSelectLamp = (event) => {
    var selectedLampId = event.target.value;

    if (!selectedLampId)
      return false;

    var selectedLamp = this.state.lampData.filter(lamp => lamp.id == selectedLampId)[0];

    this.setState({ 
      selectedLampId, 
      selectedLamp,
      selectedSizeCode: "",
      selectedLampSize: null
    });
  }

  handSelectSize = (event) => {
    var selectedSizeCode = event.target.value;
  
    if (!selectedSizeCode)
      return false;

    if (this.state.selectedLampSize) {
      this.setState({ forceReload: true });

      setTimeout(() => this.setState({ forceReload: false }), 1000);
    }

    var selectedLampSize = this.state.selectedLamp.size.filter(size => size.sizeCode == selectedSizeCode)[0];
  
    this.setState({ selectedSizeCode, selectedLampSize });
    console.log("selectedLampSize", selectedLampSize);
  }

  render() {
    return (
    <>
      <Container fluid id="view-3d">
        {this.state.lampData && <>
          <Row>
            <Col md="3">
              <InputGroup className="">
                <InputGroup.Text>Select Category</InputGroup.Text>
                <Form.Select
              >
                <option value="">Resin Lamp</option>
                <option value="">Box Game</option>
                </Form.Select>
              </InputGroup>
            </Col>

            <Col md="6">
              <InputGroup className="">
                <InputGroup.Text>Select Lamp</InputGroup.Text>
                <Form.Select
                value={this.state.selectedLampId}
                onChange={this.handleSelectLamp}
              >
                <option value="">---</option>
                {this.state.lampData.map(
                  lamp => <option value={lamp.id}>
                      {lamp.id} - {lamp.name}
                  </option>)}
                </Form.Select>
              </InputGroup>
            </Col>

            <Col md="3">
              {this.state.selectedLamp && 
              <InputGroup className="">
                <InputGroup.Text>Select Size</InputGroup.Text>
                <Form.Select
                value={this.state.selectedSizeCode}
                onChange={this.handSelectSize}
              >
                <option value="">---</option>
                {this.state.selectedLamp.size.map(
                  size => <option value={size.sizeCode}>
                    {size.sizeCode}
                  </option>)}
                </Form.Select>
              </InputGroup>}
            </Col>
            
            <Col md="3">
              {/* {this.state.selectedLampSize &&
              <Button
                className="btn-fill pull-right btn-sm"
                variant="info"
                onClick={e => this.setState({ configLamp: true })}
              >
                Config Lamp
              </Button>} */}
            </Col>

          </Row>
          
          {this.state.selectedLampSize && !this.state.forceReload && <STLViewerLamp selectedLampSize={this.state.selectedLampSize} selectedLamp={this.state.selectedLamp} sceneOptions={sceneOptions} />}
        </>}
      </Container>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lampData: state.localDataReducer.data.lampData
  }
}

export default connect(mapStateToProps, { 
  getLocalData
})(View3DLamp);