/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const login = (username, password) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
    username,
    password
  });
};

export const getUser = () => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/auth/get-user`);
};

export const authenService = {
  login,
  getUser
};
