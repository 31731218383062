import moment from "moment";
import { TYPES } from "../actions";

const initialState = {
  data: []
};

export const localDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_GET_LOCAL_DATA_SUCCESS:
      return { ...state, 
        data: {
          ...state.data,
          [action.payload.name]: action.payload.data
        }
      }

    case TYPES.ACTION_GET_ALL_BOTS_FAIL:
      return { ...state, bots: [] };
      
    default:
      return state;
  }
}
