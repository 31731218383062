// STLViewer.js
import React, { Component } from 'react';
import { BsFileBreakFill } from 'react-icons/bs';
import * as THREE from 'three';
import * as THREESTLLoader from '../../libs/three-stl-loader';
import stats from 'three/addons/libs/stats.module.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { STLExporter } from 'three/addons/exporters/STLExporter.js';
import * as BufferGeometryUtils from "three/examples/jsm/utils/BufferGeometryUtils.js";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";

import Whammy from 'react-whammy/whammy';
import { saveAs } from 'file-saver';

class STLViewer extends Component {
  constructor() {
    super();

    this.state = {
      alpha: 85,
      cameraY: 0.6,
      cameraVectorLength: 12
    };

    this.prevX = 0;
    this.prevY = 0;
    this.models = [];
    this.meshGeometryArray = [];

    this.scale = 0.1;
    this.rotateSpeed = 0.5;

    this.stlContainerRef = React.createRef()
    this.translateVector = new THREE.Vector3(0, 0, -40);
  }

  addShadowedLight = ( x, y, z, color, intensity ) => {
      const directionalLight = new THREE.DirectionalLight( color, intensity );
      directionalLight.position.set( x, y, z );
      this.scene.add( directionalLight );

      directionalLight.castShadow = true;

      const d = 1;
      directionalLight.shadow.camera.left = - d;
      directionalLight.shadow.camera.right = d;
      directionalLight.shadow.camera.top = d;
      directionalLight.shadow.camera.bottom = - d;

      directionalLight.shadow.camera.near = 1;
      directionalLight.shadow.camera.far = 4;

      directionalLight.shadow.bias = - 0.002;
  }

  onWindowResize = () => {
    this.interval = setInterval(() => {
      if (this.stlContainerRef.current) {
        let { offsetWidth, offsetHeight } = this.stlContainerRef.current;
        this.camera.aspect = offsetHeight / offsetWidth;
        this.camera.updateProjectionMatrix();
    
        this.renderer.setSize( offsetWidth, offsetHeight );

        clearInterval(this.interval);
      }
    }, 100)
  }

  onMouseWheel = (event) => {
    // this.camera.position.z += event.deltaY/500;
    if (!this.animating) {
      var cameraVectorLength = this.state.cameraVectorLength;
      this.setState({ cameraVectorLength: cameraVectorLength + (event.deltaY/500) * this.rotateSpeed });
      this.animate();

      if (this.state.recording)
        this.captureFrame();
    }
  }

  animate = () => {
    this.animating = true;
    // requestAnimationFrame( this.animate );
    console.log('ANIMATE');

    this.render3D();
    this.stats.update();
    this.animating = false;
  }

  render3D = () => {
    this.camera.position.x = Math.cos( this.state.alpha * Math.PI / 180 ) * this.state.cameraVectorLength;
    this.camera.position.z = Math.sin( this.state.alpha * Math.PI / 180) * this.state.cameraVectorLength;
    this.camera.position.y = this.state.cameraY * this.state.cameraVectorLength;

    this.camera.lookAt( this.cameraTarget );
    this.camera.updateProjectionMatrix();

    this.renderer.render( this.scene, this.camera );
  }

  addGroundPlane = () => {
    var imgPath = 'http://localhost:8080/ASSETS/BOX_GAME/imgs/table.jpg';

    const geometry = new THREE.PlaneGeometry(90, 60);
    const textureLoader = new THREE.TextureLoader();
    const texture = textureLoader.load(imgPath);
    const material = new THREE.MeshBasicMaterial({ map: texture });

    const plane = new THREE.Mesh(geometry, material);

    plane.rotation.x = - Math.PI / 2;
    plane.position.y = - 4;
    plane.position.z = 2.5;
    plane.receiveShadow = true;
    this.scene.add( plane );
  }

  addBackgrounds = () => {
    var imgPath = 'http://localhost:8080/ASSETS/BOX_GAME/imgs/bgs/bg-02.png';

    var backgroundArray = [
      {
        name: 'front',
        backgroundWidth: 8.4,
        backgroundHeight: 1.6,
        imgPath,
        x1: 16/116,
        y1: 16/168,
        x2: 100/116,
        y2: 0,
        rotation: [0, 0, 0],
        position: [0, -3.2, 4.21]
      },
      {
        name: 'left',
        backgroundWidth: 1.6,
        backgroundHeight: 8.4,
        imgPath,
        x1: 0/116,
        y2: 16/168,
        x2: 16/116,
        y1: 100/168,
        rotation: [0,-Math.PI/2, Math.PI/2],
        position: [-4.21, -3.2, 0]
      },
      {
        name: 'right',
        backgroundWidth: 1.6,
        backgroundHeight: 8.4,
        imgPath,
        x1: 1,
        y1: 16/168,
        x2: 100/116,
        y2: 100/168,
        rotation: [0, Math.PI/2, Math.PI/2],
        position: [4.21, -3.2, 0]
      },
      {
        name: 'floor',
        backgroundWidth: 8.4,
        backgroundHeight: 8.4,
        imgPath,
        x1: 16/124,
        y1: 100/168,
        x2: 100/116,
        y2: 16/168,
        rotation: [-Math.PI/2, 0, 0],
        position: [0, -2.399, 0]
      },
      {
        name: 'wall',
        backgroundWidth: 8.4,
        backgroundHeight: 6.4,
        imgPath,
        x1: 17/116,
        y1: 1,
        x2: 100/116,
        y2: 100/168,
        rotation: [0, 0, 0],
        position: [0, 0.8, -4.2]
      }
    ];

    for (var i=0; i<backgroundArray.length; i++) {
      var bg = backgroundArray[i];
  
      const geometry = new THREE.PlaneGeometry(bg.backgroundWidth, bg.backgroundHeight);
  
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(bg.imgPath);
      // texture.magFilter = THREE.LinearFilter;
      // texture.minFilter = THREE.LinearMipMapLinearFilter;
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      const material = new THREE.MeshPhongMaterial({ map: texture });
  
      var uvArray = geometry.attributes.uv.array;
      var {x1, y1, x2, y2, rotation, position } = bg;

      console.log('uvArray', uvArray);

      uvArray[0] = x1;
      uvArray[1] = y1;
      uvArray[2] = x2;
      uvArray[3] = y1;
      uvArray[4] = x1;
      uvArray[5] = y2;
      uvArray[6] = x2;
      uvArray[7] = y2;
  
      const planeImg = new THREE.Mesh(geometry, material);

      planeImg.rotation.x = rotation[0];
      planeImg.rotation.y = rotation[1];
      planeImg.rotation.z = rotation[2];
      planeImg.position.set(position[0], position[1], position[2]);
      
      this.scene.add(planeImg);
    }
  }

  addClearBox = () => {
    const geometry = new THREE.BoxGeometry(8.4, 8.7, 8.4, 1);
    const material = new THREE.MeshPhysicalMaterial({
      roughness: 0,
      transmission: 1,
      thickness: 0.3,
    });

    const mesh = new THREE.Mesh(geometry, material);
    this.scene.add(mesh);
  }

  getCenter = (mesh) => {
    const center = new THREE.Vector3();
    mesh.geometry.computeBoundingBox();
    mesh.geometry.boundingBox.getCenter(center);

    return center;
  }
  
  componentDidMount() {
    const container = document.getElementById('stl-container');
    let { offsetWidth, offsetHeight } = this.stlContainerRef.current;
    console.log('offset', offsetWidth, offsetHeight);
    this.frames = [];

    this.camera = new THREE.PerspectiveCamera(60, offsetWidth / offsetHeight, 0.1, 1000);

    // this.camera.position.set( 10, 4, 50 );

    this.cameraTarget = new THREE.Vector3( 0, 0, 0 );

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( '#A1CCD1' );
    // this.scene.fog = new THREE.Fog( 0x72645b, 10, 50 );
    
    // Binary files

    var STLLoader = new THREESTLLoader(THREE) // Added THREE
    const loader = new STLLoader();

    this.props.stlData.map((stl, idx) => {
      loader.load(stl.path, (geometry) => {
        const material = new THREE.MeshPhongMaterial( { color: stl.color, specular: 0x494949, shininess: 200 } );
        let mesh = new THREE.Mesh(geometry, material);

        mesh.scale.set( this.scale, this.scale, this.scale );
        mesh.rotation.set( - Math.PI / 2, 0, 0 );

        mesh.castShadow = true;
        mesh.receiveShadow = true;

        var box3 = new THREE.Box3();
        var size = new THREE.Vector3();

        box3.setFromObject( mesh ); // or from mesh, same answer
        console.log( box3 );

        box3.getSize( size ); // pass in size so a new Vector3 is not allocated
        console.log(stl.name, size )
        console.log(idx, 'bbox', size);

        let pos = new THREE.Vector3(stl.pos[0], stl.pos[1], stl.pos[2] + size.y / (this.scale * 2));

        // if (idx > 0)
        //   pos.z += 10;
          
        const center = new THREE.Vector3();
        geometry.computeBoundingBox();
        geometry.boundingBox.getCenter(center);
        console.log(idx, 'center', center);
        let v = pos.sub(center);
        geometry.translate(v.x, v.y, v.z);

        geometry.translate(this.translateVector.x, this.translateVector.y, this.translateVector.z);

        this.scene.add( mesh );
        this.models.push(mesh);

        this.animate();

        if (idx !=5 && idx != 0)
          this.meshGeometryArray.push(mesh.geometry);

        console.log('Info:', idx, this.getCenter(mesh));
      });
    });

    // Lights

    this.scene.add( new THREE.HemisphereLight( 0x8d7c7c, 0x494966, 3 ) );

    // this.addShadowedLight( 1, 1, 1, 0xffffff, 3.5 );
    this.addShadowedLight( 10, 20, 20, 'white', 2 );
    // this.addShadowedLight( 0.5, 1, - 1, 0xffd500, 3 );

    // axes
    // const axesHelper = new THREE.AxesHelper( 20 );
    // this.scene.add( axesHelper );

    // renderer

    this.renderer = new THREE.WebGLRenderer( { antialias: true, preserveDrawingBuffer: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( offsetWidth, offsetHeight );
    this.renderer.capabilities.getMaxAnisotropy();
    this.renderer.setClearColor(0x1f1e1c, 1);

    this.renderer.shadowMap.enabled = true;

    container.appendChild( this.renderer.domElement );

    this.stats = new stats();
    this.stats.showPanel(0);
    container.appendChild( this.stats.dom );

    this.stats.dom.style.position = 'absolute';
    this.stats.dom.style.top = '10px';
    this.stats.dom.style.left = '20px';

    // Ground
    this.addGroundPlane();

    // Background
    this.addBackgrounds();

    // Cube
    // this.addClearBox();

    //
    window.addEventListener( 'resize', this.onWindowResize );
    window.addEventListener( 'mousewheel', this.onMouseWheel);

    container.addEventListener( 'mousedown', this.handleDragStart);
    container.addEventListener( 'mousemove', this.handleDrag);
    container.addEventListener( 'mouseup', this.handleDragEnd);

    container.addEventListener("contextmenu", e => e.preventDefault());
  }

  handleExport = () => {
    // if (this.meshGeometryArray.length == this.props.stlData.length) {
    // this.meshGeometryArray.map((meshGeometry, idx) => {
    //   try {
    //     const exporter = new STLExporter();
    //     // const mergedGeometry = BufferGeometryUtils.mergeGeometries(meshGeometry);
    //     const mergedMaterial = new THREE.MeshPhongMaterial( { color: "#79BDA1", specular: 0x494949, shininess: 200 } );
    //     const mergedMesh = new THREE.Mesh(meshGeometry, mergedMaterial);
    
    //     const stlData = exporter.parse(mergedMesh);
    
    //     const blob = new Blob([stlData], { type: 'application/octet-stream' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.download = `stl_${idx}.stl`;
    //     link.click();
    //   } catch {
    //     console.log('Error at ' + idx);
    //   }
    // });

      const exporter = new STLExporter();
      const mergedGeometry = BufferGeometryUtils.mergeGeometries(this.meshGeometryArray);
      const mergedMaterial = new THREE.MeshPhongMaterial( { color: "#79BDA1", specular: 0x494949, shininess: 200 } );
      const mergedMesh = new THREE.Mesh(mergedGeometry, mergedMaterial);
  
      const stlMergedData = exporter.parse(mergedMesh);
  
      const blob = new Blob([stlMergedData], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'stlMerged.stl';
      link.click();
    // }
  }

  handleDragStart = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.mouseDown = true;
    this.prevX = event.clientX;
    this.prevY = event.clientY;
  }

  handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let rightMouseClick = event.which == 3? true: false;

    if (this.mouseDown) {
      const x = event.clientX;
      const y = event.clientY;
      // console.log("drag triggered", x, y);
  
      const dx = x - this.prevX;
      const dy = y - this.prevY;
  
      // console.log('dx dy : ', dx, dy);
  
      if (rightMouseClick && !this.animating) {
        var alpha = this.state.alpha >= 360? this.state.alpha - 360: this.state.alpha;
        if (alpha < 0)
          alpha += 360;

        this.setState({ alpha: alpha + dx * 0.5 * this.rotateSpeed });
        // this.state.alpha += dx * 0.5 * this.rotateSpeed;
        this.animate();

        if (this.state.recording)
          this.captureFrame();
      } else if (!this.animating) {
        var cameraY = this.state.cameraY;
        this.setState({ cameraY: cameraY + (dy / 100) * this.rotateSpeed });
        this.animate(); 

        if (this.state.recording)
          this.captureFrame();
      }
      
      this.prevX = x;
      this.prevY = y;
    }
  }

  handleDragEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.mouseDown = false;
  }

  captureFrame = () => {
    console.log('Caputure Frame');
    const canvas = this.renderer.domElement; // Assuming 'renderer' is your Three.js renderer
    const frameDataURL = canvas.toDataURL(); // Convert canvas to DataURL
    const frameImage = new Image();
    frameImage.src = frameDataURL;
    this.frames.push(frameImage);
  }

  dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  saveFile = (strData, filename) => {
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); //Firefox requires the link to be in the body
        link.download = filename;
        link.href = strData;
        link.click();
        document.body.removeChild(link); //remove the link when done
    } else {
        location.replace(uri);
    }
  }

  recordScreen = () => {
    if (this.creatingVideo)
      return;

    const fps = 30;

    if (!this.state.recording) {
      this.setState({ recording: true });
      // this.interval = setInterval(() => this.captureFrame(), 1000 / fps);
      this.setState({ alpha: 0 });
      this.animate();

      this.interval = setInterval(() => {
        var alpha = this.state.alpha;
        this.setState({ alpha: alpha + 0.3 });

        this.animate();
        this.captureFrame();

        if (alpha > 180) {
          clearInterval(this.interval);
          this.recordScreen();
        }
      }, 100);
    } else {
      this.setState({ recording: false });
      clearInterval(this.interval);

      this.creatingVideo = true;
      
      let { offsetWidth, offsetHeight } = this.stlContainerRef.current;
      const video = new Whammy.Video(fps);

      this.frames.forEach((frame, idx) => {
        // console.log(frame);
        const canvas = document.createElement('canvas');
        canvas.width = 1536;
        canvas.height = 856;
        const context = canvas.getContext('2d');
        context.drawImage(frame, 0, 0);
        video.add(context);
      });
      
      video.compile(false, (output) => {
        const videoUrl = URL.createObjectURL(output);

        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = 'output_video.webm';
        link.click();
      })

      this.creatingVideo = false;
    }
  }

  render() {
    return <>
    <Row style={{height: "100%"}} className="stl-viewer-component">
      <Col md="12" id="stl-container" ref={this.stlContainerRef}>
        <div />  
      </Col>
      <div id="floating-button-group">
        <div>
          <table>
            <tr>
              <td>Alpha</td>
              <td>{this.state.alpha.toFixed(2)}°</td>
            </tr>
            <tr>
              <td>CameraY</td>
              <td>{this.state.cameraY.toFixed(2)} cm</td>
            </tr>
            <tr>
              <td>Zoom</td>
              <td>{this.state.cameraVectorLength.toFixed(2)} cm</td>
            </tr>
          </table>
        </div>

        <div>
          <Button
            className="btn-fill pull-right btn-sm"
            id="export-stl"
            type="submit"
            variant="danger"
            onClick={this.handleExport}
          >
            Export All
          </Button>
        </div>
        
        <div>
          <Button
            className="btn-fill pull-right btn-sm mt-2"
            id="export-stl"
            type="submit"
            variant="danger"
            onClick={this.recordScreen}
          >
            {this.state.recording? 'Stop': 'Record'}
          </Button>
        </div>
      </div>

    </Row>
  </>
  }
};

export default STLViewer;
