import { TYPES } from "./types";
import { botService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const getAllBots = () => async (dispatch) => {
  try {
    const result = await botService.getAllBots();
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_GET_ALL_BOTS_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_GET_ALL_BOTS_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const botAction = {
  getAllBots
}
