/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const getAllTasks = () => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/task/get-all`);
}

export const getBotTasks = (taskIds) => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/task/get/${taskIds}`);
}

export const updateTasks = (tasks) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/task`, tasks);
}

export const getLogs = (query) => {
  var {task_id, from_time} = query;
  var strQuery = `task_id=${task_id}`;

  if (from_time)
    strQuery += `&time>${from_time}`;    

  return API.get(`${process.env.REACT_APP_API_URL}/api/log?${strQuery}`);
}

export const taskService = {
  getBotTasks,
  getAllTasks,
  getLogs,
  updateTasks
}
