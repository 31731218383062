import moment from "moment";
import { TYPES } from "../actions";

const initialState = {
  bots: []
};

export const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_GET_ALL_BOTS_SUCCESS:
      return { ...state, 
        bots: action.payload.data
      }

    case TYPES.ACTION_GET_ALL_BOTS_FAIL:
      return { ...state, bots: [] };
      
    default:
      return state;
  }
}
