/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table,
    Modal
  } from "react-bootstrap";

import {BsFillSuitHeartFill} from "react-icons/bs";
import { login } from "../../actions";
// https://react-icons.github.io/react-icons/icons?name=bs

class LoginChecker extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: ''
    };
  }


  componentDidMount() {

  }

  componentWillReceiveProps(props) {
  }

  handleChangeUsername = (e) => {
    this.setState({ username: e.target.value });
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.login(this.state.username, this.state.password);
  }

  render() {
    return (
      <div>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <div className="border border-3 border-primary"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <h2 className="fw-bold mb-2 text-uppercase ">Login</h2>
                  <p className=" mb-5">Please enter your login and password!</p>
                  <div className="mb-3">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicUsername">
                        <Form.Label className="text-center">
                          Username
                        </Form.Label>
                        <Form.Control type="username" placeholder="Enter username" onChange={this.handleChangeUsername}/>
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" onChange={this.handleChangePassword}/>
                      </Form.Group>
                      <div className="d-grid">
                        <Button variant="primary" type="submit" onClick={this.handleSubmit}>
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.authenReducer.token
  };
};

export default connect(mapStateToProps, { login })(LoginChecker);