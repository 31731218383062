/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import AntInfoCollectionItem from '../components/AntInfoCollectionItem'
import {
  addNewAntInfoItem,
  searchAntInfoItemsByRnDCode
} from '../actions';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
// import SKU_RND_CODES from '../utils/SKURND.json'
import { TbEmphasis } from "react-icons/tb";

import ImageModal from '../components/ImageModal';
import MarkdownVideo from '../components/MarkdownVideo';

class AddAntInfo extends Component {
  constructor(props) {
    super();
    this.state = {
      sku: '',
      rnd: '',
      infoType: 'LAYOUT',
      mdContent: '',
      SKU_RND_CODES: {},
      previousURL: props.location.pathname
    }

    props.searchAntInfoItemsByRnDCode(CONFIGS.ANTINFO.TEAMINFO_RNDCODE, true)
  }

  componentDidMount(){
    this.props.searchAntInfoItemsByRnDCode(CONFIGS.ANTINFO.TEAMINFO_RNDCODE, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.searchAntInfoItemsByRnDCode(CONFIGS.ANTINFO.TEAMINFO_RNDCODE, true);
      this.setState({ previousURL: this.props.location.pathname });
    }
  }

  componentWillReceiveProps(props) {
    if (JSON.stringify(props.teamInfoItems) != JSON.stringify(this.props.teamInfoItems)) {
      let skuConfigs = props.teamInfoItems.filter(teamInfo => teamInfo.type == CONFIGS.ANTINFO.SKU_CONFIG);

      if (skuConfigs.length > 0) {
        skuConfigs = skuConfigs[0];
        let lines = skuConfigs.md_content.split("\n").filter(v => v.trim() != '');

        console.log("@@@ SKU CONFIG", lines);

        let SKU_RND_CODES = {};

        lines.map(line => {
          let key = line.split(":")[0].trim();
          let value = line.split(":")[1].trim();
          SKU_RND_CODES[key] = value;
        })

        this.setState({ SKU_RND_CODES });
      }
    }
  }

  handleSelectSKU = (e) => {
    e.preventDefault();
    let { SKU_RND_CODES } = this.state;

    let sku = e.target.value;
    const rnd = Object.keys(SKU_RND_CODES).find(key => SKU_RND_CODES[key] === sku);
    this.setState({ rnd, sku });

    if (rnd == CONFIGS.ANTINFO.TEAMINFO_RNDCODE) {
      let teamInfo = this.props.teamInfoItems.filter(teamInfo => teamInfo.type == this.state.infoType);

      if (teamInfo.length > 0) {
        teamInfo = teamInfo[0];
        this.setState({ mdContent: teamInfo.md_content});
      }
    }
  }

  handleSelectRND = (e) => {
    e.preventDefault();
    let { SKU_RND_CODES } = this.state;

    let rnd = e.target.value;
    let sku = SKU_RND_CODES[rnd];
    this.setState({ sku, rnd });

    if (rnd == CONFIGS.ANTINFO.TEAMINFO_RNDCODE) {
      let teamInfo = this.props.teamInfoItems.filter(teamInfo => teamInfo.type == this.state.infoType);

      if (teamInfo.length > 0) {
        teamInfo = teamInfo[0];
        this.setState({ mdContent: teamInfo.md_content});
      }
    }
  }

  handleSelectInfoType = (e) => {
    e.preventDefault();

    this.setState({ infoType: e.target.value });

    if (this.state.rnd == CONFIGS.ANTINFO.TEAMINFO_RNDCODE) {
      let teamInfo = this.props.teamInfoItems.filter(teamInfo => teamInfo.type == e.target.value);

      if (teamInfo.length > 0) {
        teamInfo = teamInfo[0];
        this.setState({ mdContent: teamInfo.md_content});
      }
    }
  }

  handleChangeContent = (e) => {
    e.preventDefault();

    this.setState({ mdContent: e.target.value });
  }

  handleSaveAntInfo = (e) => {
    let { sku, rnd, infoType, mdContent } = this.state;

    let antInfoItem = {
      mid: [rnd, infoType].join("_"),
      sku: sku.split("_")[0],
      name: sku.split("_")[1],
      rnd,
      type: infoType,
      status: 1,
      md_content: mdContent.trim()
    };

    this.props.addNewAntInfoItem(antInfoItem);
  }

  render() {
    let { SKU_RND_CODES } = this.state;

    let skuCodes = Object.values(SKU_RND_CODES).sort();
    let rndCodes = Object.keys(SKU_RND_CODES);

    return (
      <>
        <Container fluid id="antInfoCollection-page"
        className="paper-style"
        // onClick={this.handleClick}
        >
          <Row className="mb-3">
            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>RnD</InputGroup.Text>
                <Form.Select                  
                  value={this.state.rnd}
                  onChange={this.handleSelectRND}
                >
                  <option value="">Select RND</option>
                  {rndCodes.map(rnd => <option value={rnd}>{rnd}</option>)}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col md="5">
              <InputGroup className="mb-3">
                <InputGroup.Text>SKU</InputGroup.Text>
                <Form.Select
                  value={this.state.sku}
                  onChange={this.handleSelectSKU}
                >
                  <option value="">Select SKU</option>
                  {skuCodes.map(sku => <option value={sku}>{sku}</option>)}
                </Form.Select>
              </InputGroup>
            </Col>
            <Col md="3">
              <InputGroup className="mb-3">
                <InputGroup.Text>Type</InputGroup.Text>
                  <Form.Select
                    value={this.state.infoType}
                    onChange={this.handleSelectInfoType}
                  >
                  <option value="LAYOUT">LAYOUT</option>
                  <option value="TO_MAU">TO_MAU</option>
                  <option value="XUONG_RESIN">XUONG_RESIN</option>
                  <option value="RND">RND</option>
                  <option value="SKU_CONFIG">SKU_CONFIG</option>
                </Form.Select>
              </InputGroup>
            </Col>
            <Col md="1">
              <Button
                  className="btn-fill pull-right btn-sm"
                  type="submit"
                  variant="primary"
                  onClick={this.handleSaveAntInfo}
                >
                  Save
                </Button>
            </Col>
          </Row>

          <Row>
            <Form.Group controlId="formContent">
              {/* <Form.Label>Content</Form.Label> */}
              <Form.Control
                as="textarea"
                rows={15}
                value={this.state.mdContent}
                onChange={this.handleChangeContent}
              />
            </Form.Group>
          </Row>

          <Row className="ant-info-markdown mt-3">
            <h4>Preview</h4>
            <ReactMarkdown children={this.state.mdContent}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  img: ImageModal,
                  video: MarkdownVideo
                }} />
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    teamInfoItems: state.antInfoItemReducer.teamInfoItems
  }
    
}

export default connect(mapStateToProps, {
  addNewAntInfoItem,
  searchAntInfoItemsByRnDCode
})(AddAntInfo);