/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import ShopItem from '../components/ShopItem'
import { getShops, setFlag } from '../actions';
import ScrollToTop from "react-scroll-to-top";
import Pagination from "../components/Pagination";
import { CONFIGS } from "../configs";
import { getTranslate, LANGUAGE_KEYS } from "../utils";
import queryString from 'query-string';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup 
  } from "react-bootstrap";

class Shop extends Component {
  constructor() {
    super();
    this.state = {
      shops: [],
      totalCount: 0,
      query: this.initQuery,
    };
  }

  initQuery = {
    limit: 24,
    itemsPerPage: 24,
    itemOffset: 0,
    currentPage: 1,
    isUpdateQuery: true,
    sortBy: '-total_sales',
    sortOrder: 1,
    totalSoldMin: 0,
    totalSoldMax: 100, 
    rating: 4.8,
    sold30DaysMin: 0,
    sold30DaysMax: 100
  }

  updatePage = (itemOffset, newPage) => {
    if (newPage == this.state.currentPage)
        return;

    this.setState({ shops: [], isUpdateQuery: false, loadingData: true }, () => {
        this.props.setFlag(CONFIGS.FLAGS.FINISH_GET_SHOPS, false);
        this.props.getShops(this.state.query);
    })
  }

  componentDidMount() {
    const queryStringFromUrl = window.location.search;
    const urlParams = new URLSearchParams(queryStringFromUrl);
    var itemsPerPage = urlParams.get('itemsPerPage');

    if (itemsPerPage) {
      const queryObject = queryString.parse(queryStringFromUrl);
      
      ['itemsPerPage', 'itemOffset', 'currentPage'].map(key => {
        queryObject[key] = parseInt(queryObject[key])
      })

      queryObject.itemOffset = (queryObject.currentPage - 1) * queryObject.itemsPerPage;
      
      this.setState({
        query: queryObject
      }, () => {
        this.props.getShops(this.state.query);
      })
    } else {
      var query = JSON.parse(localStorage.getItem('shop_query'));

      if (query) {

        this.setState({
          query: {...this.initQuery,
            'totalSold': query['totalSold'] | 0,
            'sortBy': query['sortBy'],
            'sortOrder': query['sortOrder'] | 1
          }
        }, () => {
          this.props.getShops(this.state.query);
        })
      }
    }
  }

  componentWillReceiveProps(props) {
    var { shops, totalCount } = props;

    if (totalCount > 0 && totalCount != this.state.totalCount) {
      console.log("ABC")
      this.setState({ shops, totalCount })
    }
  }

  handleChangeTotalSoldMin= (e) => {
    this.setState({ 
      query: {...this.state.query, totalSoldMin: e.target.value.trim() }
    })
  }

  handleChangeTotalSoldMax= (e) => {
    this.setState({ 
      query: {...this.state.query, totalSoldMax: e.target.value.trim() }
    })
  }

  handleChangeRating= (e) => {
    this.setState({ 
      query: {...this.state.query, rating: e.target.value.trim() }
    })
  }

  handleChangeSold30DaysMin= (e) => {
    this.setState({ 
      query: {...this.state.query, sold30DaysMin: e.target.value.trim() }
    })
  }

  handleChangeSold30DaysMax= (e) => {
    this.setState({ 
      query: {...this.state.query, sold30DaysMax: e.target.value.trim() }
    })
  }

  handleSelectSort = (e) => {
    this.setState({ 
      query: {...this.state.query, sortBy: e.target.value }
    })
  }

  handleSelectSortOrder = (e) => {
    this.setState({ 
      query: {...this.state.query, sortOrder: parseInt(e.target.value) }
    })
  }

  updateUrl = (query) => {
    const { history } = this.props;
    const queryStr = queryString.stringify(query);

    history.push({
      pathname: '/admin/shop',
      search: queryStr,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log('handleSubmit');
    this.updateUrl(this.state.query)
    this.props.getShops(this.state.query);
    localStorage.setItem('shop_query', JSON.stringify(this.state.query));
  }

  updatePage = (itemOffset, newPage) => {
    if (newPage == this.state.currentPage)
        return;

    this.setState({ 
      query: {...this.state.query, itemOffset: itemOffset, currentPage: newPage},
      forceUpdateHeyData: false
    }, () => {
      this.setState({ shops: [], isUpdateQuery: false }, () => {
        this.updateUrl(this.state.query)
        this.props.getShops(this.state.query);
      })
    })
  }

  renderSearchFilter = () => {

    return <>
            <Row className="">
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_SALES)} ≥</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.query.totalSoldMin}
                    onChange={this.handleChangeTotalSoldMin}
                    placeholder={getTranslate(LANGUAGE_KEYS.TEXT_MIN)}
                    aria-label="daily_view"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_SALES)} ≤</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.query.totalSoldMax}
                    onChange={this.handleChangeTotalSoldMax}
                    placeholder={getTranslate(LANGUAGE_KEYS.TEXT_MAX)}
                    aria-label="daily_view"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SHOP_SOLD_90_DAYS)} ≥</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.sold30DaysMin}
                    onChange={this.handleChangeSold30DaysMin}
                    placeholder={getTranslate(LANGUAGE_KEYS.TEXT_MIN)}
                    aria-label="price-min"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
              <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SHOP_SOLD_90_DAYS)} ≤</InputGroup.Text>
                  <Form.Control
                    value={this.state.query.sold30DaysMax}
                    onChange={this.handleChangeSold30DaysMax}
                    placeholder={getTranslate(LANGUAGE_KEYS.TEXT_MAX)}
                    aria-label="price-min"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

            </Row>
            <Row>
              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_RATING)} ≥</InputGroup.Text>
                  <Form.Control
                    defaultValue=""
                    value={this.state.query.rating}
                    onChange={this.handleChangeRating}
                    placeholder={getTranslate(LANGUAGE_KEYS.TEXT_RATING)}
                    aria-label="daily_view"
                    aria-describedby="basic-addon1"
                  />
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SORT_BY)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.sortBy}
                  onChange={this.handleSelectSort}
                >
                  <option value="-total_sales_hey">{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_SALES)}</option>
                  <option value="-sale_90_days">{getTranslate(LANGUAGE_KEYS.TEXT_SHOP_SOLD_90_DAYS)}</option>
                  <option value="-rating_value">{getTranslate(LANGUAGE_KEYS.TEXT_RATING)}</option>
                  <option value="-total_listings">{getTranslate(LANGUAGE_KEYS.TEXT_TOTAL_LISTINGS)}</option>
                  <option value="-opening_date">{getTranslate(LANGUAGE_KEYS.TEXT_OPENING_DATE)}</option>
                  <option value="-created_at">{getTranslate(LANGUAGE_KEYS.TEXT_CREATED_DATE)}</option>
                  </Form.Select>
                </InputGroup>
              </Col>

              <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_SORT_ORDER)}</InputGroup.Text>
                  <Form.Select
                  value={this.state.query.sortOrder}
                  onChange={this.handleSelectSortOrder}
                >
                  <option value="1">{getTranslate(LANGUAGE_KEYS.TEXT_DECREASE)}</option>
                  <option value="-1">{getTranslate(LANGUAGE_KEYS.TEXT_INCREASE)}</option>
                  </Form.Select>
                </InputGroup>
              </Col>

              {/* <Col md="3">
                <InputGroup className="mb-3">
                  <InputGroup.Text>{getTranslate(LANGUAGE_KEYS.TEXT_FROM)}</InputGroup.Text>
                  <Form.Control type="date" name="dob" placeholder="Date of Birth" 
                  // value={this.state.query.dateFrom}
                  // onChange={this.handleChangeDateFrom}
                />
                </InputGroup>
              </Col> */}

              <Col md="3">
                <Button
                  className="btn-fill pull-right ml-3 btn-sm"
                  type="submit"
                  variant="primary"
                  onClick={this.handleSubmit}
                  style={{ "padding": "5px" }}
                >
                  {getTranslate(LANGUAGE_KEYS.TEXT_SEARCH)}
                </Button>
              </Col>
            </Row>

            </>
  }

  render() {
    return (
    <>
      <Container fluid>
        {this.renderSearchFilter()}
        <Row>
          <h5>
            <b>{getTranslate(LANGUAGE_KEYS.TEXT_FOUND_X_SHOPS, [<span className='text-danger'>{this.state.totalCount}</span>])}:</b>
          </h5>
        </Row>
        <Row>
            {this.state.shops
            .map(shop => <Col md="3">
                <ShopItem key={shop.shop_id} shop={shop} />
            </Col>)}
        </Row>
      </Container>
      <Pagination totalItems = {this.state.totalCount} itemsPerPage = {this.state.query.itemsPerPage} itemOffset = {this.state.query.itemOffset} updatePage={this.updatePage}/>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    shops: state.shopReducer.shops,
    totalCount: state.shopReducer.totalCount
    // finishGetShops: state.miscReducer.flag[CONFIGS.FLAGS.FINISH_GET_SHOPS]
  };
};

export default connect(mapStateToProps, { getShops, setFlag })(Shop);