/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const getShops = (query) => {
  var {
    itemsPerPage,
    currentPage,
    isUpdateQuery,
    totalSoldMin,
    totalSoldMax,
    sold30DaysMin,
    sold30DaysMax,
    rating,
    sortBy,
    sortOrder
  } = query;

  const itemOffset = (currentPage - 1) * itemsPerPage;

  if (sortOrder < 0) {
    sortBy = '-' + sortBy;
    sortBy = sortBy.replace('--', '');
  }

  query = `is_update_query=${isUpdateQuery}`;

  if (totalSoldMin != "")
    query += `&total_sales_hey>=${totalSoldMin}`;

  if (totalSoldMax != "")
    query += `&total_sales_hey<=${totalSoldMax}`;

  if (sold30DaysMin != "")
    query += `&sale_90_days>=${sold30DaysMin}`;

  if (sold30DaysMax != "")
    query += `&sale_90_days<=${sold30DaysMax}`;
  
  if (rating != "")
    query += `&rating_value>=${rating}`;

  query += `&limit=${itemsPerPage}`;
  query += `&skip=${itemOffset}`;
  query += `&sort=${sortBy}`;

  return API.get(`${process.env.REACT_APP_API_URL}/api/shop/get?${query}`);
};

export const shopService = {
  getShops
};
