import moment from "moment";
import { TYPES } from "../actions";

const initialState = {
  tasks: [],
  botTasks: [],
  logs: {}
};

export const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_GET_ALL_TASKS_SUCCESS:
      return { ...state, 
        tasks: action.payload.data
      }

    case TYPES.ACTION_GET_ALL_BOT_TASKS_SUCCESS:
      var botTasks = action.payload.data;
      var botTaskIds = botTasks.map(task => task._id);
      var tasks = state.tasks.map(task => {
        if (botTaskIds.indexOf(task._id) >= 0)
          return botTasks[botTaskIds.indexOf(task._id)];

        return task;
      });
      
      return { ...state, 
        botTasks,
        tasks
      }

    case TYPES.ACTION_DELETE_TASK_SUCCESS:
      return { ...state, 
        tasks: state.tasks.filter(task => task._id != action.payload.taskId)
      }

    case TYPES.ACTION_GET_LOGS_SUCCESS:
      var { task_id, logs, isAppendLog } = action.payload;

      if (isAppendLog)
        return { ...state,
          logs: { ...state.logs, [task_id]: [...state.logs[task_id], ...logs]}
        }
      else
        return { ...state,
          logs: { ...state.logs, [task_id]: logs}
        }

    case TYPES.ACTION_GET_ALL_TASKS_FAIL:
      return { ...state, tasks: [] };
      
    default:
      return state;
  }
};
