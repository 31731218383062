import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import * as moment from 'moment'
import { 
} from '../actions';
import Pagination from "../components/Pagination";
import ScrollUpButton from 'react-scroll-up-button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import queryString from 'query-string';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    InputGroup,
    Collapse,
    Modal,
    Table
  } from "react-bootstrap";

import STLViewer from '../components/STLViewer';
import pokemonData from '../assets/pokemon_data.json';

class View3D extends Component {
  constructor() {
    super();
    // 0001 0002 0003 0004 0005 0006 0007 0008 0094 0134 0143 0151 0778
    this.state = {
      stlIds: [
        '0001', '0002', '0003',
        '0004', '0005', '0006'
      ],

      posArray: [
        [-0.3, 0.3],
        [-0.15, 0],
        [-0.3, -0.3],
        [0.3, 0.3],
        [0.15, 0],
        [0.3, -0.3],
      ],

      baseSTL: {
        size: [84, 84, 16],
        fileName: 'rectangle.stl',
        sizeSymbol: 'L'
      }
    }
  }

  getSTLData = () => {
    let stlPath = 'http://localhost:8080';

    let colorArray = [
      "#ECEE81",
      "#8DDFCB",
      "#82A0D8",
      "#EDB7ED",
      "#A1CCD1",
      "#E9B384"
    ];

    let stlData = [];
    
    stlData.push({
      name: 'Base',
      path: `${stlPath}/3D_MODELS/MISC/BOX-GAME/${this.state.baseSTL.fileName}`,
      pos: [0, 0, 0],
      color: "#79BDA1"
    });

    // stlData.push({
    //   name: 'Frame',
    //   path: `${stlPath}/frame.stl`,
    //   pos: [0, 0, 0],
    //   color: "silver"
    // });

    this.state.stlIds.map((id, idx) => {
      let stl = pokemonData.filter(pokemon => pokemon.id == ['POKEMON', id].join("_"))[0];
      let posXY = this.state.posArray[idx];
      let base = this.state.baseSTL;
      let lengthX = base.size[0];
      let lengthY = base.size[1];
      let lengthZ = base.size[2];

      console.log(posXY, base.size);

      stlData.push({
        name: stl.name,
        path:  `${stlPath}/3D_MODELS/POKEMON_ALL/${id}_${stl.name}/%40${base.sizeSymbol}/m01.stl`,
        pos: [lengthX * posXY[0], lengthY * posXY[1], lengthZ],
        color: colorArray[idx]
        // pos: [0, 0, 0]
      })
    });

    console.log('stlData', stlData);

    return stlData;
  }

  render() {
    return (
    <>
      <Container fluid id="view-3d">
        <STLViewer stlData={this.getSTLData()} />
      </Container>
    </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
  }
}

export default connect(mapStateToProps, { 

})(View3D);