import { combineReducers } from "redux";
import { authenReducer } from "./authenReducer";
import { myRecordReducer } from "./myRecordReducer";
import { listingReducer } from "./listingReducer";
import { shopReducer } from "./shopReducer";
import { taskReducer } from "./taskReducer";
import { antInfoCollectionReducer } from "./antInfoCollectionReducer";
import { antInfoItemReducer } from "./antInfoItemReducer";
import { botReducer } from "./botReducer";
import { socketReducer } from "./socketReducer";
import { miscReducer } from "./miscReducer";
import { localDataReducer } from "./localDataReducer";

const reducers = combineReducers({
  authenReducer,
  myRecordReducer,
  listingReducer,
  shopReducer,
  taskReducer,
  antInfoCollectionReducer,
  botReducer,
  socketReducer,
  miscReducer,
  localDataReducer,
  antInfoItemReducer
});

export default reducers;
