/* eslint-disable camelcase */
import { CONFIGS } from "../configs";
import { API } from "./API";

export const searchAntInfoItemsByRnDCode = (rndCode) => {
  return API.get(`${process.env.REACT_APP_API_URL}/api/ant-info-item/get-all?rnd=${rndCode}`);
}

export const addNewAntInfoItem = (antInfoItem) => {
  return API.post(`${process.env.REACT_APP_API_URL}/api/ant-info-item`, [
    antInfoItem
  ]);
}

export const antInfoItemService = {
  searchAntInfoItemsByRnDCode,
  addNewAntInfoItem
}
