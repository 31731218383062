/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import AntInfoCollectionItem from '../components/AntInfoCollectionItem'
import {
  searchAntInfoItemsByRnDCode,
  getUser
} from '../actions';
import { CircleSpinnerOverlay, FerrisWheelSpinner } from 'react-spinner-overlay'
import { CONFIGS } from "../configs";
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  InputGroup,
  Collapse,
  Modal,
  Table
} from "react-bootstrap";

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import smoothscroll from 'smoothscroll-polyfill';
import { TbHemispherePlus } from "react-icons/tb";
// import SKU_RND_CODES from '../utils/SKURND.json'
import ImageModal from '../components/ImageModal';
import MarkdownVideo from '../components/MarkdownVideo';

// Khởi động polyfill
smoothscroll.polyfill();

class AntInfoCollection extends Component {
  constructor(props) {
    super();
    this.state = {
      showButton: true,
      keyword: '',
      antInfoItems: [],
      SKU_RND_CODES: {},
      showTeamInfo: localStorage.getItem('showTeamInfo') == 'false'? false : true,
      previousURL: props.location.pathname
    }

    props.searchAntInfoItemsByRnDCode(CONFIGS.ANTINFO.TEAMINFO_RNDCODE, true)
  }

  componentDidMount() {
    this.props.searchAntInfoItemsByRnDCode(CONFIGS.ANTINFO.TEAMINFO_RNDCODE, true);
    // if (typeof window !== 'undefined') {
    //   window.addEventListener('scroll', this.handleScroll);
    // }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.location.pathname !== prevProps.location.pathname) {
    //   this.props.searchAntInfoItemsByRnDCode(CONFIGS.ANTINFO.TEAMINFO_RNDCODE, true);
    //   this.setState({ previousURL: this.props.location.pathname });
    // }
  }

  componentWillUnmount() {
    // if (typeof window !== 'undefined') {
    //   window.removeEventListener('scroll', this.handleScroll);
    // }
  }

  handleScroll = (e) => {
    // e.preventDefault();
    // console.log("AAA handleScroll", window.scrollY);

    // if (window.scrollY > 100) {
    //   this.setState({ showButton: true });
    // } else {
    //   this.setState({ showButton: true });
    // }
  }

  scrollToTop = (e) => {
    // e.preventDefault();
    // console.log("AAA scrollToTop", window.scrollY, window)
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  componentWillReceiveProps(props) {
    this.setState({ antInfoItems: props.antInfoItems });

    if (JSON.stringify(props.teamInfoItems) != JSON.stringify(this.props.teamInfoItems)) {
      let skuConfigs = props.teamInfoItems.filter(teamInfo => teamInfo.type == CONFIGS.ANTINFO.SKU_CONFIG);

      if (skuConfigs.length > 0) {
        skuConfigs = skuConfigs[0];
        let lines = skuConfigs.md_content.split("\n").filter(v => v.trim() != '');

        let SKU_RND_CODES = {};

        lines.map(line => {
          let key = line.split(":")[0].trim();
          let value = line.split(":")[1].trim();
          SKU_RND_CODES[key] = value;
        })

        this.setState({ SKU_RND_CODES });
      }
    }
  }

  handleChangeKeyword = (e) => {
    e.preventDefault();

    this.setState({ keyword: e.target.value });

    if (e.target.value.length < 4)
      return;

    setTimeout(() => {
      this.handleSearch();
    }, 1000);
  }

  handleSearch = () => {
    let { SKU_RND_CODES } = this.state;

    let skuCodes = Object.values(SKU_RND_CODES).map(v => v.split("_")[0].toLowerCase());
    // let rndCodes = Object.keys(SKU_RND_CODES).map(v => v.toLowerCase());

    let keyword = this.state.keyword.toLowerCase();

    if (skuCodes.indexOf(keyword) >= 0) {
      const rndCode = Object.keys(SKU_RND_CODES).find(key => SKU_RND_CODES[key].toLowerCase().indexOf(keyword) >= 0);
      keyword = rndCode;
    }

    this.props.searchAntInfoItemsByRnDCode(keyword);
  }

  handleSelectSKU = (e) => {
    e.preventDefault();
    let { SKU_RND_CODES } = this.state;

    let sku = e.target.value;
    const rndCode = Object.keys(SKU_RND_CODES).find(key => SKU_RND_CODES[key] === sku);

    this.setState({ keyword: sku.split("_")[0] });

    this.props.searchAntInfoItemsByRnDCode(rndCode);
  }

  handleLogout = () => {
    localStorage.setItem("eit_token", "");
    window.location.reload();
  }

  toggleShowTeamInfo = () => {
    let { showTeamInfo } = this.state;
    localStorage.setItem("showTeamInfo", !showTeamInfo);

    this.setState({ showTeamInfo: !showTeamInfo });
  }


  render() {
    let teamInfos = [];

    if (this.props.user.role == CONFIGS.USER_ROLES.XUONG_RESIN) {
      teamInfos = this.props.teamInfoItems.filter(teamInfo => teamInfo.type == CONFIGS.ANTINFO.XUONG_RESIN);
    } else if (this.props.user.role == CONFIGS.USER_ROLES.TO_MAU_LEAD) {
      teamInfos = this.props.teamInfoItems.filter(teamInfo => teamInfo.type == CONFIGS.ANTINFO.TO_MAU);
    } else if (this.props.user.role == CONFIGS.USER_ROLES.RND || this.props.user.role == CONFIGS.USER_ROLES.ROOT
      || this.props.user.role == CONFIGS.USER_ROLES.ADMIN) {
      teamInfos = this.props.teamInfoItems.filter(teamInfo => teamInfo.type == CONFIGS.ANTINFO.XUONG_RESIN 
        || teamInfo.type == CONFIGS.ANTINFO.TO_MAU
        || teamInfo.type == CONFIGS.ANTINFO.RND);
    }

    let antInfoItems = this.state.antInfoItems;

    if (this.props.user.role == CONFIGS.USER_ROLES.XUONG_RESIN) {
      antInfoItems = antInfoItems.filter(antInfo => antInfo.type == CONFIGS.ANTINFO.XUONG_RESIN);
    } else if (this.props.user.role == CONFIGS.USER_ROLES.TO_MAU_LEAD) {
      antInfoItems = antInfoItems.filter(antInfo => antInfo.type != CONFIGS.ANTINFO.XUONG_RESIN);
    }

    antInfoItems = antInfoItems.sort((a, b) => a.type.localeCompare(b.type))

    let { SKU_RND_CODES } = this.state;

    let skuCodes = Object.values(SKU_RND_CODES).filter(sku => !sku.includes('ANT00')).sort();

    return (
      <>
        <Container fluid id="antInfoCollection-page"
          className="paper-style"
        // onClick={this.handleClick}
        >
          <Row>
            <Col md="12 mb-2">
              <div className="logout-btn" onClick={this.handleLogout}><span className="text-blue">{this.props.user.username}</span> - Logout</div>

              <InputGroup className="">
                <InputGroup.Text>SKU</InputGroup.Text>
                <Form.Select
                  value={this.state.sku}
                  onChange={this.handleSelectSKU}
                >
                  <option value="">Chọn sản phẩm</option>
                  {skuCodes.map(sku => <option value={sku}>{sku}</option>)}
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>

          <Row className="search-product mb-3">
            <Col md="12">
              <InputGroup>
                <InputGroup.Text>Search</InputGroup.Text>
                <Form.Control
                  // className='text-red'
                  value={this.state.keyword}
                  onChange={this.handleChangeKeyword}
                  placeholder="Điền mã RD, mã SKU, keyword ..."
                />
              </InputGroup>
            </Col>
          </Row>

          <Row className="team-info">
            <Col md="12">
              <div className="toggle-show-team-info-btn" title="Team info" onClick={this.toggleShowTeamInfo}>{this.state.showTeamInfo ? "Ẩn" : "Hiện thông tin"}</div>

              {this.state.showTeamInfo && teamInfos.map(teamInfo => <ReactMarkdown children={teamInfo.md_content.trim()}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  img: ImageModal,
                  video: MarkdownVideo
                }} />)}
            </Col>

          </Row>

          <Row className="ant-info-markdown mt-3">
            {/* {this.state.antInfoItems.length == 0 && <div class="loading">
            <FerrisWheelSpinner loading={true}　color="#17a2b8" />
          </div>} */}
            {antInfoItems.map((antInfo, idx) => <>
              <div className="info-title text-red text-uppercase text-center">{antInfo.sku}. {antInfo.name} ({antInfo.rnd})</div>
              <ReactMarkdown children={antInfo.md_content}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                  img: ImageModal,
                  video: MarkdownVideo
                }}
              />
            </>)}

          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    antInfoItems: state.antInfoItemReducer.antInfoItems,
    teamInfoItems: state.antInfoItemReducer.teamInfoItems,
    user: state.authenReducer.user
  }
}

export default connect(mapStateToProps, {
  searchAntInfoItemsByRnDCode,
  getUser
})(AntInfoCollection);