import { TYPES } from "../actions";

const initialState = {
    messages: localStorage.getItem('message_logs')?
              JSON.parse(localStorage.getItem('message_logs')): []
};

export const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_ADD_MESSAGE:
        return {
            ...state,
            messages: [...state.messages, action.payload],
          };

    case TYPES.ACTION_CLEAR_ALL_MESSAGES:
      return {
          ...state,
          messages: [],
        };
        
    default:
      return state;
  }
};