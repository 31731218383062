import { TYPES } from "./types";
import { antInfoItemService } from "../services"; 
import { setFlag } from "./miscAction";
import { CONFIGS } from "../configs";

export const searchAntInfoItemsByRnDCode = (rndCode, teamInfo = false) => async (dispatch) => {
  try {
    const result = await antInfoItemService.searchAntInfoItemsByRnDCode(rndCode);
    const data = result.data.data;
    
    if (data) {
      if (teamInfo)
        dispatch({
          type: TYPES.ACTION_SEARCH_TEAMINFO_BY_RND_CODE_SUCCESS,
          payload: {data},
        });
      else dispatch({
        type: TYPES.ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_SUCCESS,
        payload: {data},
      });
    } else {
      if (teamInfo)
        dispatch({
          type: TYPES.ACTION_SEARCH_TEAMINFO_BY_RND_CODE_FAIL,
          payload: {},
        });
      else dispatch({
        type: TYPES.ACTION_SEARCH_ANTINFOITEMS_BY_RND_CODE_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const addNewAntInfoItem = (antInfoItem) => async (dispatch) => {
  try {
    const result = await antInfoItemService.addNewAntInfoItem(antInfoItem);
    const data = result.data.data;
    
    if (data) {
      dispatch({
        type: TYPES.ACTION_ADD_NEW_ANTINFOITEM_SUCCESS,
        payload: {data},
      });
    } else {
      dispatch({
        type: TYPES.ACTION_ADD_NEW_ANTINFOITEM_FAIL,
        payload: {},
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        message: error,
      },
    });
  }
}

export const antInfoItemAction = {
  addNewAntInfoItem
}
