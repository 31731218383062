import moment from "moment";
import { TYPES } from "../actions";

const initialState = {
  listings: [],
  listingByIds: [],
  totalCount: 0,
  tasks: [],
  listing30DaysSold: []
};

const convertListing = (heyListing) => {
  var validKeys = [
    'u', 'original_creation', 'original_creation_days', 'last_modified', 'last_modified_minutes', 'daily_views', 'sold', 'views_24h', 'tags', 'categories', 'hey', 'listing_id', 'views', 'num_favorers'
  ];

  var listing = {}

  validKeys.map(key => {
    listing[key] = heyListing[key];
  })

  var arr = ['num_favorers', 'views']
  arr.map(key => {
    listing[key] = parseInt(heyListing[key].replace(",", ""))
  })

  listing['last_check_hey'] = moment().format();
  listing['favorite_rate'] = parseFloat((listing['num_favorers'] * 100 / listing['views']).toFixed(2))

  return listing;
}

export const listingReducer = (state = initialState, action) => {
  switch (action.type) {

    case TYPES.ACTION_GET_LISTINGS_SUCCESS:
      return { ...state, 
        listings: action.payload.data.result, 
        totalCount: action.payload.data.totalCount > -1? action.payload.data.totalCount: state.totalCount,
        updateHey: false
      };

    case TYPES.ACTION_GET_LISTING_BY_IDS_SUCCESS:
      return { ...state,
        listingByIds: action.payload.data
      } 

    case TYPES.ACTION_GET_ALL_TASKS_SUCCESS:
      return { ...state, 
        tasks: action.payload.data
      }

    case TYPES.ACTION_UPDATE_LISTINGS_HEYDATA_SUCCESS:  
      var objListings = {};
      
      state.listings.map(listing => {
        objListings[listing.listing_id] = listing;
      })
      
      action.payload.data.map(listing => convertListing(listing)).map(listing => { 
        objListings[listing.listing_id] = listing;
      });

      return { ...state, 
        listings: state.listings.map(listing => Object.assign({}, listing, objListings[listing.listing_id])),
        updateHey: true
      }

    case TYPES.ACTION_GET_LISTINGS_FAIL:
      return { ...state, listings: [] };

    case TYPES.ACTION_GET_LISTINGS_30_DAYS_SOLD_SUCCESS:
      return { ...state, 
        listing30DaysSold: action.payload.data.result
      }
      
    default:
      return state;
  }
};
