import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import CSS của react-image-lightbox

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleShow = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { src, alt } = this.props;
    const { isOpen } = this.state;

    return (
      <>
      <p className="text-center">
      <img src={src} alt={alt} style={{ cursor: 'pointer' }} onClick={this.handleShow} />
      </p>
        
        
        {isOpen && (
          <Lightbox
            mainSrc={src}
            onCloseRequest={this.handleClose}
            imageTitle={alt}
          />
        )}
      </>
    );
  }
}

export default ImageModal;
