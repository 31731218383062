/* eslint-disable func-names */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { } from '../../actions';
import { CONFIGS } from '../../configs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Row,
    Col,
    Table
  } from "react-bootstrap";

import { FiEdit } from "react-icons/fi";
import { AiFillHeart } from "react-icons/ai";
import moment from "moment";
// https://react-icons.github.io/react-icons/icons?name=bs

class AntInfoCollectionItem extends Component {
  constructor() {
    super();
    this.state = {
    };
  }


  componentDidMount() {
  }

  componentWillReceiveProps(props) {
  }

  render() {
    var antInfoCollection = this.props.antInfoCollection;
    return <Card className='listing-item'>
      <a href={`antInfoCollection/${antInfoCollection._id}`}>
        <Card.Img variant="top" src={antInfoCollection.img_cover_url} />
      </a>

      {!this.props.favorite &&
        <FiEdit 
          className="btn-add-to-antInfoCollection" 
          title="Edit antInfoCollection"
          onClick={(e) => this.props.handleShowPopupEditAntInfoCollection(antInfoCollection)}
        />}

      <Card.Header>
        <h5 className="text-center">{this.props.favorite? 
          <AiFillHeart className="icon-favorite"/>
          :antInfoCollection.name}
        </h5>
      </Card.Header>
      <Card.Body>
        {antInfoCollection.description}
      </Card.Body>
    </Card>
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

export default connect(mapStateToProps, { })(AntInfoCollectionItem);