import React, { Component } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, compose } from "redux";
import { legacy_createStore as createStore } from 'redux';
import thunk from "redux-thunk";
import reducers from "./reducers";
import ReactDOM from "react-dom/client";
import RedirectComponent from "components/Redirect";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/pagination.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/demo.css";

import Admin from "layouts/Admin.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

window.store = store;

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        <Route path="/team" render={(props) => <Admin {...props} />} />
        <Route path="/:prefix/:sitename" component={RedirectComponent} />
        <Redirect from="/" to="/team/product" />
      </Switch>
    </BrowserRouter>
  </Provider>
);
