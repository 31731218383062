
import axios from "axios";

const get = (url, data = {}, headers = null) => {
    const accessToken = window.localStorage.getItem("eit_token");
    if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return axios.get(url);
}

const getHey = (url, data = {}, accessToken) => {
    if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return axios.get(url);
}

const post = (url, data = {}, headers = null) => {
    const accessToken = window.localStorage.getItem("eit_token");
    if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return axios.post(url, data);
}

export const API = {
    get,
    getHey,
    post,
};
