import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';

function Pagination({ totalItems, itemsPerPage, itemOffset, updatePage }) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  // const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(totalItems / itemsPerPage);

  const currentPage = parseInt(itemOffset / itemsPerPage)

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalItems;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    // setItemOffset(newOffset);
    console.log("Pagination updatepage ", updatePage);
    updatePage(newOffset, event.selected + 1);
  };

  return (
    <div id="pagination">
      <ReactPaginate
        forcePage={currentPage}
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default Pagination;