import { TYPES } from "../actions";

const initialState = {
  authenInfo: {},
  isLoggedIn: false,
  user: null
};

export const authenReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.ACTION_LOGIN_SUCCESS:
      window.localStorage.setItem("eit_token", action.payload.token);

      return { ...state, 
        isLoggedIn: true, 
        token: action.payload.token,
        user: action.payload.user
      };

    case TYPES.ACTION_GET_USER_SUCCESS:
      return { ...state, 
        isLoggedIn: true,
        user: action.payload.user 
      };

    case TYPES.ACTION_LOGIN_FAIL:
      return { ...state, isLoggedIn: false, token: "" };
      
    default:
      return state;
  }
};
