// import { LANGUAGE_KEYS, getTranslate, getCurrentLanguage } from "../utils";

export const CONFIGS = {
  FLAGS: {
    SELECTED_TIME_DURATION: "SELECTED_TIME_DURATION",
    UPDATE_CHART_COUNT: "UPDATE_CHART_COUNT",
    FINISH_GET_LISTINGS: "FINISH_GET_LISTINGS",
    FINISH_UPDATE_LISTINGS_HEYDATA: "FINISH_UPDATE_LISTINGS_HEYDATA",
    FINISH_GET_SHOPS: "FINISH_GET_SHOPS",

    TOAST_MESSAGE: "TOAST_MESSAGE",
    CURRENT_POPUP_LISTING_ID: "CURRENT_POPUP_LISTING_ID"
  },

  TASK_STATUS: {
		DELETED: -2,
		ERROR: -1,
		SCHEDULE: 0,
		IN_PROGESS: 1,
		FINISH: 2
	},

  ANTINFOCOLLECTION_TYPES: {
		FAVORITES: 'FAVORITES',
		USER_CREATED: 'USER_CREATED',
		GENERATED: 'GENERATED'
	},

  ANTINFO: {
    TEAMINFO_RNDCODE: 'RD0000',
    TO_MAU: 'TO_MAU',
    XUONG_RESIN: 'XUONG_RESIN',
    LAYOUT: 'LAYOUT',
    RND: 'RND',
    SKU_CONFIG: 'SKU_CONFIG'
  },

  USER_ROLES: {
    ROOT: 1,
    ADMIN: 2,
    RND: 3,
    TO_MAU_LEAD: 4,
    TO_MAU_PUBLIC: 5,
    SALE: 6,
    XUONG_RESIN: 7
  }
}